  // // frontend/src/App.js
  // import React, { useState } from 'react';
  // import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
  // import Header from './components/Header';
  // import Footer from './components/Footer';
  // import Home from './components/Home';
  // import About from './components/About';
  // import Project from './components/Project';
  // import Gallery from './components/Gallery';
  // import DonationCenters from './components/DonationCenters';
  // import BankDetails from './components/BankDetails';
  // import Contact from './components/Contact';
  // import DonationReceipt from './components/DonationReceipt';
  // import SharedDonationReceipt from './components/SharedDonationReceipt';


  // // Import the DonateModal
  // import DonateModal from './components/DonateModal';

  // function App() {
  //   // State to manage the Donate modal visibility
  //   const [isDonateModalOpen, setDonateModalOpen] = useState(false);

  //   // Open the modal
  //   const openDonateModal = () => {
  //     setDonateModalOpen(true);
  //   };

  //   // Close the modal
  //   const closeDonateModal = () => {
  //     setDonateModalOpen(false);
  //   };

  //   return (
  //     <Router>
  //       <div className="flex flex-col min-h-screen">
  //         {/* Header */}
  //         <Header />

  //         {/* Main Content */}
  //         <div className="flex-grow">
  //           <Routes>
  //             <Route path="/" element={<Home />} />
  //             <Route path="/about" element={<About />} />
  //             <Route path="/project" element={<Project />} />
  //             <Route path="/gallery" element={<Gallery />} />
  //             <Route path="/donation-centres" element={<DonationCenters />} />
  //             <Route path="/bank-details" element={<BankDetails />} />
  //             <Route path="/contact" element={<Contact />} />
  //             <Route path="/receipt" element={<DonationReceipt />} />
  //             <Route path="/receipt-share" element={<SharedDonationReceipt />} />
  //           </Routes>
  //         </div>

  //         {/* Footer */}
  //         <Footer />

  //         {/* 
  //           Floating Donate Now Button:
  //           - On very small screens, we add extra bottom spacing (bottom-20) so it's
  //             not hidden by the phone's bottom bar.
  //           - On medium and up (md:...), revert to a smaller offset (bottom-6).
  //         */}
  //         <div className="fixed bottom-20 right-4 md:bottom-6 md:right-6 z-50">
  //           <button
  //             onClick={openDonateModal}
  //             className="bg-primary hover:bg-secondary text-white font-bold py-3 px-6 rounded shadow-lg transition-colors
  //                       focus:outline-none focus:ring-2 focus:ring-primary"
  //             aria-label="Donate Now"
  //           >
  //             Donate Now
  //           </button>
  //         </div>

  //         {/* Render the Donate Modal when open */}
  //         {isDonateModalOpen && <DonateModal onClose={closeDonateModal} />}
  //       </div>
  //     </Router>
  //   );
  // }

  // export default App;







  import React, { useState } from 'react';
  import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
  import Header from './components/Header';
  import Footer from './components/Footer';
  import Home from './components/Home';
  import About from './components/About';
  import Project from './components/Project';
  import Gallery from './components/Gallery';
  import DonationCenters from './components/DonationCenters';
  import BankDetails from './components/BankDetails';
  import Contact from './components/Contact';
  import DonationReceipt from './components/DonationReceipt';
  import SharedDonationReceipt from './components/SharedDonationReceipt';
  import DonateModal from './components/DonateModal';
  import TrusteeMandal from './components/TrusteeMandal';
  import TermsAndPolicies from './components/TermsAndPolicies';
  
  // 1) Import new gallery sub-pages:
  import Infrastructure from './components/Infrastructure';
  import ThreeDPhoto from './components/ThreeDPhoto';
  import AnimalHelp from './components/AnimalHelp';
  
  function App() {
    const [isDonateModalOpen, setDonateModalOpen] = useState(false);
  
    const openDonateModal = () => setDonateModalOpen(true);
    const closeDonateModal = () => setDonateModalOpen(false);
  
    return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/project" element={<Project />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/donation-centres" element={<DonationCenters />} />
              <Route path="/bank-details" element={<BankDetails />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/receipt" element={<DonationReceipt />} />
              <Route path="/receipt-share" element={<SharedDonationReceipt />} />
              <Route path="/trusti-mandal" element={<TrusteeMandal />} />
              <Route path="/terms-and-refund" element={<TermsAndPolicies />} />
  
              {/* 2) Add the three new gallery sub-routes */}
              <Route path="/infrastructure" element={<Infrastructure />} />
              <Route path="/3d-photo" element={<ThreeDPhoto />} />
              <Route path="/animal-help" element={<AnimalHelp />} />
            </Routes>
          </div>
  
          <Footer />
  
          {/* Floating Donate Button */}
          <div className="fixed bottom-20 right-4 md:bottom-6 md:right-6 z-50">
            <button
              onClick={openDonateModal}
              className="bg-primary hover:bg-secondary text-white font-bold py-3 px-6 rounded shadow-lg transition-colors focus:outline-none focus:ring-2 focus:ring-primary"
              aria-label="Donate Now"
            >
              Donate Now
            </button>
          </div>
  
          {/* Donate Modal */}
          {isDonateModalOpen && <DonateModal onClose={closeDonateModal} />}
        </div>
      </Router>
    );
  }
  
  export default App;
  