// src/components/Infrastructure.jsx
import React from 'react';

const Infrastructure = () => {
  return (
    <main className="bg-white">
      {/* Banner */}
      <section
        className="relative bg-center bg-cover h-72 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/banner/gallery_banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-4xl font-bold">Our Photos</h1>
        </div>
      </section>

      {/* Title */}
      <section className="py-8">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-3xl font-bold mb-2 text-primary">Infrastructure</h1>
        </div>
      </section>

      {/* Grid of images */}
      <section className="container mx-auto px-4 pb-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Example: from /images/infra */}
          {Array.from({ length: 15 }, (_, i) => i + 1).map((num) => (
            <div className="relative group" key={num}>
              <img
                src={`/images/infra/img${num}.webp`}
                alt={`Infrastructure ${num}`}
                className="w-full object-cover"
              />
              <a
                href={`/images/infra/img${num}.webp`}
                target="_blank"
                rel="noreferrer"
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition"
              >
                <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100 transition" />
              </a>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default Infrastructure;
