// frontend/src/components/TermsAndPolicies.jsx
import React from 'react';

const TermsAndPolicies = () => {
  return (
    <main className="bg-white">
      {/* Banner / Hero Section */}
      <section
        className="relative bg-center bg-cover h-60 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/terms-banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white px-4">
          <h1 className="text-3xl md:text-4xl font-bold">
            Terms & Conditions and Refund Policy
          </h1>
        </div>
      </section>

  {/* Main Content Section */}
      <section className="py-12">
        <div className="container mx-auto px-4 space-y-8">
          <div>
            <h2 className="text-2xl font-bold mb-4 text-primary">
              1. Acceptance of Terms
            </h2>
            <p className="text-dark leading-relaxed">
              By accessing or using our website, you agree to be bound by these Terms &amp; Conditions.
              If you do not agree with any of these terms, you are prohibited from using or accessing
              this site.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4 text-primary">
              2. Donations &amp; Tax Exemptions
            </h2>
            <p className="text-dark leading-relaxed">
              All donations to our organization are used for the welfare of animals. Depending on
              the laws in your region, you may be eligible for certain tax exemptions or deductions
              for your donation. Please consult your tax advisor to understand how these may apply
              to you.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4 text-primary">
              3. Return &amp; Refund Policy
            </h2>
            <p className="text-dark leading-relaxed">
              <strong>No Refunds for Donations:</strong> Once a donation is made, it is considered
              final and non-refundable. If you believe an error occurred during your donation, please
              contact us immediately at{' '}
              <a
                href="mailto:jivadayamandalbhachau@yahoo.com"
                className="text-blue-500 underline"
              >
                jivadayamandalbhachau@yahoo.com
              </a>{' '}
              or{' '}
              <a
                href="tel:+919925042930"
                className="text-blue-500 underline"
              >
                +91 99250 42930
              </a>
              . We will review your case, but generally, donations are not refunded.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4 text-primary">
              4. Changes to These Policies
            </h2>
            <p className="text-dark leading-relaxed">
              We may revise these Terms &amp; Policies at any time without notice. By using this
              website, you agree to be bound by the then-current version.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4 text-primary">
              5. Contact Information
            </h2>
            <p className="text-dark leading-relaxed">
              For any questions or concerns about these Terms &amp; Conditions or our Return/Refund
              Policy, please contact:
            </p>
            <p className="mt-2">
              <strong>Email:</strong>{' '}
              <a
                href="mailto:jivadayamandalbhachau@yahoo.com"
                className="text-blue-500 underline"
              >
                jivadayamandalbhachau@yahoo.com
              </a>
              <br />
              <strong>Phone:</strong>{' '}
              <a
                href="tel:+919925042930"
                className="text-blue-500 underline"
              >
                +91 99250 42930
              </a>
            </p>
          </div>
        </div>
      </section>
    </main>

  );
};

export default TermsAndPolicies;
