// frontend/src/components/DonateModal.jsx
import React, { useState } from 'react';

const DonateModal = ({ onClose }) => {
  const [donation, setDonation] = useState({
    name: '',
    whatsapp: '',
    email: '',
    amount: '',
    note: '',
    aadharCard: '',
    panCard: '',
  });
  const [loading, setLoading] = useState(false);

  // Handler for input changes
  const handleChange = (e) => {
    setDonation({ ...donation, [e.target.name]: e.target.value });
  };

  // Handler for selecting a default amount
  const handleAmountSelect = (amt) => {
    setDonation({ ...donation, amount: amt });
  };

  const handleDonate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Create order
      const res = await fetch('/api/donations/create-order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(donation),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "Order creation failed");
      }
      // Prepare Razorpay options
      const options = {
        key: data.key,
        amount: data.amount,
        currency: data.currency,
        name: "JivDaya Trust",
        description: "Donation",
        order_id: data.order_id,
        handler: async function (response) {
          const verifyRes = await fetch('/api/donations/verify-payment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              donation,
            }),
          });
          const verifyData = await verifyRes.json();
          if (verifyData.success) {
            alert("Donation successful. Thank you!");

            // Save updated donation (with receiptNumber) and redirect
            localStorage.setItem("lastDonation", JSON.stringify(verifyData.donation));
            onClose();
            window.location.href = "/receipt";
          } else {
            alert("Payment verification failed. Please try again or contact support.");
          }
        },
        prefill: {
          name: donation.name,
          email: donation.email,
          contact: donation.whatsapp,
        },
        notes: {
          note: donation.note,
        },
        theme: {
          color: "#792a21",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (err) {
      console.error(err);
      alert("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  // Array of default amounts (as strings)
  const defaultAmounts = ["100", "200", "500", "1000", "2000"];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-primary">Donate Now</h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900">
            &times;
          </button>
        </div>
        <form onSubmit={handleDonate} className="space-y-4">
          <div>
            <label className="block text-sm font-semibold text-dark">Name*</label>
            <input
              type="text"
              name="name"
              value={donation.name}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-dark">WhatsApp Number*</label>
            <input
              type="text"
              name="whatsapp"
              value={donation.whatsapp}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-dark">Email (optional)</label>
            <input
              type="email"
              name="email"
              value={donation.email}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-dark">Aadhar Card Number (optional)</label>
            <input
              type="text"
              name="aadharCard"
              value={donation.aadharCard}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-dark">PAN Card Number (optional)</label>
            <input
              type="text"
              name="panCard"
              value={donation.panCard}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-dark">Amount (₹)*</label>
            <div className="flex flex-wrap gap-2 mb-2">
              {defaultAmounts.map((amt) => (
                <button
                  key={amt}
                  type="button"
                  onClick={() => handleAmountSelect(amt)}
                  className={`border rounded px-3 py-1 transition-colors ${
                    donation.amount === amt ? "bg-primary text-white" : "bg-white text-dark hover:bg-gray-200"
                  }`}
                >
                  {amt}
                </button>
              ))}
            </div>
            <input
              type="number"
              name="amount"
              placeholder="Enter amount"
              value={donation.amount}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-dark">Note</label>
            <textarea
              name="note"
              value={donation.note}
              onChange={handleChange}
              rows="3"
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:border-primary"
            ></textarea>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-primary text-white py-2 rounded hover:bg-secondary transition-colors"
          >
            {loading ? "Processing..." : "Proceed to Donate"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DonateModal;
