// src/components/DonationCenters.jsx
import React from 'react';
import {
  FaUserCircle,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaPhoneAlt,
  FaMap,
  FaWhatsapp
} from 'react-icons/fa';

// Donation centers data
const donationCentersData = [
  {
    city: "Surat",
    name: "Shree Kanaksuri Ahinsadham",
    address: "Shop No.322, Shiven Square, Opp. Pyramid Platina, Pal, Surat - 395009",
    phone: "09512342930",
  },
  {
    city: "Mumbai",
    name: "Bhavesh N. Gandhi",
    address: "Ritual Creation, 209 Cosmos Platinum, Opp Ashish Industry, Gokhale Road, Dadar-W, Mumbai - 400028",
    phone: "09892566570",
    phone2: "09892566570",
  },
  {
    city: "Mumbai",
    name: "Rohit Dharamshee Kubadiya",
    address: "Shantabai gokhale nivas, First Flor, Gokhale Road(North), Dadar(West), Mumbai- 400 028",
    phone: "09820632115",
    phone2: "09820632115",
  },
  {
    city: "Ahmedabad",
    name: "Jayesh Pragajibhai Koradiya",
    address: "Nav bharat sahitiya temple, 202 Pelakin house, Gujarat Chambers of commerce compound, ashram road, Ahmedabad",
    phone: "09824031650",
    phone2: "09824031650",
  },
  {
    city: "Mumbai",
    name: "Jayantilal Chhaganlal Vora",
    address: "Bhavana Dresess, 504 - Central Plaza, Shiwaji Chawk, Malad East - 400097",
    phone: "09967372765",
    phone2: "09967372765",
  },
  {
    city: "Bhachau",
    name: "Himatlal Jethalal Mehta",
    address: "Mahavir kirana store, near chirai gate, bhachau kutch, 370140",
    phone: "09879989024",
    phone2: "09879989024",
  },
];

// Helper functions
function formatIndianPhoneDisplay(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  if (digits.length === 12 && digits.startsWith("91")) {
    const country = digits.slice(0, 2);
    const part1 = digits.slice(2, 7);
    const part2 = digits.slice(7);
    return `+${country} ${part1} ${part2}`;
  }
  return `+${digits}`;
}

function formatIndianPhoneLink(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  return digits;
}

const DonationCenters = () => {
  return (
    <main className="bg-white">
      {/* Banner Section */}
      <section
        className="relative bg-center bg-cover h-80 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/donation-centers-banner.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-5xl font-bold">Our Donation Centres</h1>
        </div>
      </section>

      {/* Donation Centers Listing */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          {/* Heading */}
          <div className="mb-12 text-center">
            <p className="text-xl text-dark font-semibold">
              <span className="text-secondary">You can donate to us at</span>
            </p>
            <h1 className="text-4xl font-bold text-primary mt-4">All Donation Centers</h1>
          </div>

          {/* Cards Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {donationCentersData.map((center, index) => {
              // If phone2 doesn't exist, use phone for both
              const hasPhone2 = !!center.phone2;
              const phoneCall = center.phone;
              const phoneWhatsApp = hasPhone2 ? center.phone2 : center.phone;

              return (
                <div
                  key={index}
                  className="bg-[#FCE1CB] border border-[#DFB499] p-6 md:p-8 rounded-lg shadow hover:shadow-2xl transition-all transform hover:scale-105"
                >
                  {/* City */}
                  <h3 className="text-2xl font-bold text-dark mb-4">{center.city}</h3>

                  {/* Name */}
                  <div className="flex items-start space-x-2 mb-3">
                    <FaUserCircle className="text-primary mt-1" />
                    <p className="text-lg md:text-xl font-semibold">{center.name}</p>
                  </div>

                  {/* Address */}
                  <div className="flex items-start space-x-2 mb-3">
                    <FaMapMarkerAlt className="text-primary mt-1" />
                    <p className="text-lg md:text-xl">{center.address}</p>
                  </div>

                  {/* Call phone */}
                  <div className="flex items-start space-x-2 mb-3">
                    <FaPhoneAlt className="text-primary mt-1" />
                    <a
                      href={`tel:+${formatIndianPhoneLink(phoneCall)}`}
                      className="text-lg md:text-xl underline hover:text-primary"
                    >
                      {formatIndianPhoneDisplay(phoneCall)}
                    </a>
                  </div>

                  {/* WhatsApp phone */}
                  <div className="flex items-start space-x-2 mb-3">
                    <FaWhatsapp className="text-primary mt-1" />
                    <a
                      href={`https://wa.me/${formatIndianPhoneLink(phoneWhatsApp)}?text=Hi`}
                      className="text-lg md:text-xl underline hover:text-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatIndianPhoneDisplay(phoneWhatsApp)}
                    </a>
                  </div>

                  {/* Pincode (if any) */}
                  {center.pincode && (
                    <div className="flex items-start space-x-2">
                      <FaMap className="text-primary mt-1" />
                      <p className="text-lg md:text-xl">Pincode: {center.pincode}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </main>
  );
};

export default DonationCenters;
