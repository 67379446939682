// src/components/BankDetails.jsx
import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa'; // for copy icon

const BankDetails = () => {
  // Example UPI ID
  const UPI_ID = "9925042390@okbizaxis";

  // Handler to copy the UPI ID text
  const handleUPICopy = () => {
    navigator.clipboard.writeText(UPI_ID);
    alert("UPI ID copied to clipboard!");
  };

  return (
    <main className="bg-white">
      {/* Banner Section */}
      <section
        className="relative bg-center bg-cover h-72 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/bank-details-banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-4xl font-bold">Bank Details</h1>
        </div>
      </section>

      {/* Bank Details Section */}
  {/* Bank Details Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="mb-8 text-center">
            <h2 className="text-3xl font-bold text-primary">Shree Kanaksuri Ahinsadham Bhachau</h2>
          </div>
          <div className="flex justify-center mb-8">
            <img src="/images/bank-logo.webp" alt="Bank Logo" className="max-w-xs" />
          </div>

          {/* Table of bank info */}
          <div className="overflow-x-auto mb-8">
            <table className="min-w-full bg-white border">
              <tbody>
                <tr className="border-b">
                  <td className="p-2 font-semibold">Cheque in the name of</td>
                  <td className="p-2"><strong>Jivdaya Mandal Bhachau</strong></td>
                </tr>
                <tr className="border-b">
                  <td className="p-2 font-semibold">Bank Name</td>
                  <td className="p-2">Bank of Baroda</td>
                </tr>
                <tr className="border-b">
                  <td className="p-2 font-semibold">Branch</td>
                  <td className="p-2">Bhachau (Kutch), Gujarat (India)</td>
                </tr>
                <tr className="border-b">
                  <td className="p-2 font-semibold">Pin Code No.</td>
                  <td className="p-2">370140</td>
                </tr>
                <tr className="border-b">
                  <td className="p-2 font-semibold">Saving Account No.</td>
                  <td className="p-2"><strong>58580100005871</strong></td>
                </tr>
                <tr className="border-b">
                  <td className="p-2 font-semibold">IFSC/RTGS/NEFT</td>
                  <td className="p-2">BARB0BHAKUT</td>
                </tr>
                <tr>
                  <td className="p-2 font-semibold">PAN No.</td>
                  <td className="p-2">AAATJ1447J</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Section for UPI ID with copy functionality */}
          <div className="mt-8 flex items-center justify-center space-x-2">
            <span className="font-semibold">UPI ID:</span>
            <span>{UPI_ID}</span>
            <button
              onClick={handleUPICopy}
              className="text-primary hover:opacity-80 flex items-center space-x-1"
              title="Copy UPI ID"
            >
              <FaCopy />
            </button>
          </div>

          {/* GPay/QR Code Section */}
          <div className="flex justify-center mt-12">
            <img src="/images/gpay-link.jpg" alt="GPay Link" className="max-w-xs" />
          </div>
        </div>
      </section>
    </main>

  );
};

export default BankDetails;
