// src/components/AnimalHelp.jsx
import React from 'react';

const AnimalHelp = () => {
  // Example images in /public/images/animal-help
  const imageFilenames = [
    'img1.webp',
    'img2.webp',
    'img3.webp',
    'img4.webp',
    'img5.webp',
    'img6.webp',
    'img7.webp',
    'img8.webp',
    // etc...
  ];

  // Example videos in /public/images/animal-help
  const videos = [
    'Video1.mp4',
    'Video2.mp4',
    'Video3.mp4',
    'Video4.mp4',
    // etc...
  ];

  return (
    <main className="bg-white">
      {/* Banner */}
      <section
        className="relative bg-center bg-cover h-72 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/banner/gallery_banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-4xl font-bold">Our Photos</h1>
        </div>
      </section>

      {/* Title */}
      <section className="py-8">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-3xl font-bold mb-2 text-primary">Animal Help</h1>
        </div>
      </section>

      {/* Grid of images */}
      <section className="container mx-auto px-4 pb-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {imageFilenames.map((file, idx) => (
            <div className="relative group" key={idx}>
              <img
                src={`/images/animal-help/${file}`}
                alt={`animal-help-${idx}`}
                className="w-full object-cover"
              />
              <a
                href={`/images/animal-help/${file}`}
                target="_blank"
                rel="noreferrer"
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition"
              >
                <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100 transition" />
              </a>
            </div>
          ))}
        </div>
      </section>

      {/* Video Section */}
      <section className="container mx-auto px-4 pb-16">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-bold">Video</h2>
        </div>
        <div className="flex flex-wrap gap-4">
          {videos.map((videoFile, idx) => (
            <div className="w-full md:w-1/2 lg:w-1/3" key={idx}>
              <video
                width="100%"
                height="300"
                controls
                src={`/images/animal-help/${videoFile}`}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default AnimalHelp;
