// // frontend/src/components/DonationReceipt.jsx
// import React, { useEffect, useState, useCallback } from "react";
// import { jsPDF } from "jspdf";
// import { toWords } from "number-to-words";

// // Original dimensions divided by 4
// const DESIGN_WIDTH = Math.round(2482 / 4); // 621
// const DESIGN_HEIGHT = Math.round(3508 / 4); // 877

// // If needed, tweak this to fine-tune vertical alignment in the PDF.
// const PDF_TEXT_OFFSET = 12; // small offset to compensate baseline vs. top

// // Downscaled text fields coordinates (each original coordinate divided by 4 and rounded)
// const fields = [
//   { key: "receiptNumber", label: "Receipt No", x: 479, y: 183 },
//   { key: "referenceId", label: "Reference ID", x: 160, y: 390 },
//   { key: "createdAt", label: "Date", x: 103, y: 183 },
//   { key: "name", label: "Name", x: 160, y: 230 },
//   { key: "city", label: "City", x: 160, y: 284 },
//   { key: "whatsapp", label: "WhatsApp", x: 160, y: 257 },
//   { key: "amount", label: "Amount", x: 160, y: 418 },
//   { key: "mode", label: "Online", x: 160, y: 365 },
//   { key: "amountInWords", label: "Amount in Words", x: 160, y: 444 },
//   { key: "note", label: "Note", x: 160, y: 311 },
//   { key: "aadhar", label: "Aadhar", x: 160, y: 338 },
//   { key: "pan", label: "PAN", x: 399, y: 338 },
// ];

// // Helper function to format a date string as DD/MM/YYYY
// const formatDate = (dateStr) => {
//   const date = new Date(dateStr);
//   const day = date.getDate().toString().padStart(2, "0");
//   const month = (date.getMonth() + 1).toString().padStart(2, "0");
//   const year = date.getFullYear();
//   return `${day}/${month}/${year}`;
// };

// const DonationReceipt = () => {
//   const [donation, setDonation] = useState(null);
//   const [scale, setScale] = useState(1);

//   // Calculate a scale factor so that the receipt fits within the viewport (90% width)
//   const calculateScale = useCallback(() => {
//     const maxWidth = window.innerWidth * 0.9;
//     const newScale = Math.min(maxWidth / DESIGN_WIDTH, 1);
//     setScale(newScale);
//   }, []);

//   useEffect(() => {
//     const stored = localStorage.getItem("lastDonation");
//     if (stored) {
//       setDonation(JSON.parse(stored));
//     }
//     calculateScale();
//     window.addEventListener("resize", calculateScale);
//     return () => window.removeEventListener("resize", calculateScale);
//   }, [calculateScale]);

//   // Convert donation.amount to words using number-to-words package
//   const getAmountInWords = () => {
//     if (!donation?.amount) return "N/A";
//     return `Rupees ${toWords(Number(donation.amount))} only`;
//   };

//   // Handle PDF download
//   const handleDownloadPDF = () => {
//     if (!donation) return;
//     // Create jsPDF document with downsized dimensions
//     const doc = new jsPDF({
//       orientation: "portrait",
//       unit: "px",
//       format: [DESIGN_WIDTH, DESIGN_HEIGHT],
//     });

//     // Load and draw the downsized background image
//     const img = new Image();
//     img.src = "/images/reciept_image.jpg";
//     img.onload = () => {
//       // Draw background covering the downsized design dimensions
//       doc.addImage(img, "JPG", 0, 0, DESIGN_WIDTH, DESIGN_HEIGHT);

//       // Use the same font size as on screen (11px) + bold
//       doc.setFont("helvetica", "bold");
//       doc.setFontSize(12);
//       doc.setTextColor(0, 0, 0);

//       // Render each text field at its downsized coordinates
//       fields.forEach((field) => {
//         let text = "";
//         if (field.key === "receiptNumber") {
//           text = donation.receiptNumber || "N/A";
//         } else if (field.key === "referenceId") {
//           text = donation.payment_id ? String(donation.payment_id) : "";
//         } else if (field.key === "createdAt") {
//           text = formatDate(donation.createdAt);
//         } else if (field.key === "name") {
//           text = donation.name || "N/A";
//         } else if (field.key === "whatsapp") {
//           text = donation.whatsapp || "N/A";
//         } else if (field.key === "amount") {
//           text = donation.amount ? String(donation.amount) : "";
//         } else if (field.key === "amountInWords") {
//           text = getAmountInWords();
//         } else if (field.key === "note") {
//           text = donation.note || "N/A";
//         } else if (field.key === "aadhar") {
//           text = donation.aadharCard ? String(donation.aadharCard) : "";
//         } else if (field.key === "pan") {
//           text = donation.panCard ? String(donation.panCard) : "";
//         } else {
//           text = field.label;
//         }
//         // Add a small vertical offset for baseline alignment
//         doc.text(text, field.x, field.y + PDF_TEXT_OFFSET);
//       });

//       // Save the PDF with a dynamic filename
//       doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
//     };

//     // Fallback if image fails to load
//     img.onerror = () => {
//       doc.setFont("helvetica", "bold");
//       doc.setFontSize(11);
//       doc.text("Could not load background image!", 50, 50);
//       doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
//     };
//   };

//   if (!donation) {
//     return (
//       <div className="p-4">
//         <h1 className="text-xl font-bold">No donation found</h1>
//         <p>Please donate first.</p>
//       </div>
//     );
//   }

//   return (
//     <div className="my-8 flex flex-col items-center">
//       <h1 className="text-2xl font-bold mb-4">Donation Receipt Preview</h1>

//       {/* On‐Screen Preview */}
//       <div
//         style={{
//           width: DESIGN_WIDTH,
//           height: DESIGN_HEIGHT,
//           transform: `scale(${scale})`,
//           transformOrigin: "top left",
//           border: "1px solid #ccc",
//           boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
//           position: "relative",
//           backgroundImage: 'url("/images/reciept_image.jpg")',
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//         }}
//       >
//         {fields.map((field) => {
//           let value = "";
//           if (field.key === "receiptNumber") {
//             value = donation.receiptNumber || "N/A";
//           } else if (field.key === "referenceId") {
//             value = donation.payment_id ? String(donation.payment_id) : "";
//           } else if (field.key === "createdAt") {
//             value = formatDate(donation.createdAt);
//           } else if (field.key === "name") {
//             value = donation.name || "N/A";
//           } else if (field.key === "whatsapp") {
//             value = donation.whatsapp || "N/A";
//           } else if (field.key === "amount") {
//             value = donation.amount ? String(donation.amount) : "";
//           } else if (field.key === "amountInWords") {
//             value = getAmountInWords();
//           } else if (field.key === "note") {
//             value = donation.note || "N/A";
//           } else if (field.key === "aadhar") {
//             value = donation.aadharCard ? String(donation.aadharCard) : "";
//           } else if (field.key === "pan") {
//             value = donation.panCard ? String(donation.panCard) : "";
//           } else {
//             value = field.label;
//           }
//           return (
//             <div
//               key={field.key}
//               style={{
//                 position: "absolute",
//                 top: field.y,
//                 left: field.x,
//                 fontSize: "11px",
//                 fontWeight: "bold",
//                 color: "#000",
//                 whiteSpace: "nowrap",
//               }}
//             >
//               {value}
//             </div>
//           );
//         })}
//       </div>

//       {/* Download PDF Button */}
//       <button
//         onClick={handleDownloadPDF}
//         className="mt-4 bg-[brown] text-white px-4 py-2 rounded hover:opacity-80"
//       >
//         Download PDF
//       </button>
//     </div>
//   );
// };

// export default DonationReceipt;







// frontend/src/components/DonationReceipt.jsx
import React, { useEffect, useState, useCallback } from "react";
import { jsPDF } from "jspdf";
import { toWords } from "number-to-words";
import { useSearchParams } from "react-router-dom";

// Original dimensions divided by 4
const DESIGN_WIDTH = Math.round(2482 / 4); // 621
const DESIGN_HEIGHT = Math.round(3508 / 4); // 877

// Vertical offset to compensate for jsPDF's baseline anchoring
const PDF_TEXT_OFFSET = 12;

// Downscaled text fields coordinates (each original coordinate divided by 4 and rounded)
const fields = [
  { key: "receiptNumber", label: "Receipt No", x: 479, y: 183 },
  { key: "referenceId", label: "Reference ID", x: 160, y: 390 },
  { key: "createdAt", label: "Date", x: 103, y: 183 },
  { key: "name", label: "Name", x: 160, y: 230 },
  { key: "city", label: "", x: 160, y: 284 },
  { key: "whatsapp", label: "WhatsApp", x: 160, y: 257 },
  { key: "amount", label: "Amount", x: 160, y: 417 },
  { key: "mode", label: "Online", x: 160, y: 364 },
  { key: "amountInWords", label: "Amount in Words", x: 160, y: 444 },
  { key: "note", label: "Note", x: 160, y: 310 },
  { key: "aadhar", label: "Aadhar", x: 160, y: 337 },
  { key: "pan", label: "PAN", x: 399, y: 337 },
];

// Helper function to format a date string as DD/MM/YYYY
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const DonationReceipt = () => {
  const [donation, setDonation] = useState(null);
  const [scale, setScale] = useState(1);
  const [sponsorImageUrl, setSponsorImageUrl] = useState(null);

  const [searchParams] = useSearchParams();
  const donationId = searchParams.get("id");

  // Calculate a scale factor so that the receipt fits within the viewport (90% width)
  const calculateScale = useCallback(() => {
    const maxWidth = window.innerWidth * 0.9;
    const newScale = Math.min(maxWidth / DESIGN_WIDTH, 1);
    setScale(newScale);
  }, []);

  // Fetch donation from backend if an "id" query parameter exists
  const fetchDonation = async (id) => {
    try {
      const res = await fetch(`/api/donations/${id}`);
      if (!res.ok) {
        throw new Error("Donation not found");
      }
      const data = await res.json();
      setDonation(data);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch sponsor image (if any)
  const fetchSponsor = useCallback(async () => {
    try {
      const res = await fetch("/api/sponsors");
      if (!res.ok) {
        throw new Error("Failed to fetch sponsor info");
      }
      const sponsors = await res.json();
      if (sponsors.length > 0) {
        // Construct full path for the sponsor image
        setSponsorImageUrl(`/uploads/sponsors/${sponsors[0].filename}`);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (donationId) {
      fetchDonation(donationId);
    } else {
      // Fallback: use donation stored in localStorage (set after successful donation)
      const stored = localStorage.getItem("lastDonation");
      if (stored) {
        setDonation(JSON.parse(stored));
      }
    }
    calculateScale();
    window.addEventListener("resize", calculateScale);
    fetchSponsor();
    return () => window.removeEventListener("resize", calculateScale);
  }, [calculateScale, donationId, fetchSponsor]);

  // Convert donation.amount to words using number-to-words package
  const getAmountInWords = () => {
    if (!donation?.amount) return "N/A";
    return `Rupees ${toWords(Number(donation.amount))} only`;
  };

  // Handle PDF download
  const handleDownloadPDF = () => {
    if (!donation) return;

    // Create jsPDF document with downsized dimensions
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [DESIGN_WIDTH, DESIGN_HEIGHT],
    });

    // Load and draw the downsized background image
    const img = new Image();
    img.src = "/images/reciept_image.jpg"; // your base receipt background
    img.onload = () => {
      doc.addImage(img, "JPG", 0, 0, DESIGN_WIDTH, DESIGN_HEIGHT);

      // Use the same font settings as on screen (bold, size ~17)
      doc.setFont("helvetica", "bold");
      doc.setFontSize(17);
      doc.setTextColor(0, 0, 0);

      // Render each text field
      fields.forEach((field) => {
        let text = "";
        if (field.key === "receiptNumber") {
          text = donation.receiptNumber || "N/A";
        } else if (field.key === "referenceId") {
          text = donation.payment_id ? String(donation.payment_id) : "";
        } else if (field.key === "createdAt") {
          text = formatDate(donation.createdAt);
        } else if (field.key === "name") {
          text = donation.name || "N/A";
        } else if (field.key === "whatsapp") {
          text = donation.whatsapp || "N/A";
        } else if (field.key === "amount") {
          text = donation.amount ? String(donation.amount) : "";
        } else if (field.key === "amountInWords") {
          text = getAmountInWords();
        } else if (field.key === "note") {
          text = donation.note || "N/A";
        } else if (field.key === "aadhar") {
          text = donation.aadharCard ? String(donation.aadharCard) : "";
        } else if (field.key === "pan") {
          text = donation.panCard ? String(donation.panCard) : "";
        } else {
          // "mode" or any other leftover keys
          text = field.label;
        }
        doc.text(text, field.x, field.y + PDF_TEXT_OFFSET);
      });

      // If a sponsor image exists, add it at the bottom
      if (sponsorImageUrl) {
        const sponsorImg = new Image();
        sponsorImg.src = sponsorImageUrl;
        sponsorImg.onload = () => {
          // Define sponsor image dimensions
          const sponsorImgWidth = DESIGN_WIDTH;
          const sponsorImgHeight = 200; // adjust as needed
          const sponsorY = DESIGN_HEIGHT - sponsorImgHeight; 
          doc.addImage(sponsorImg, "JPG", 0, sponsorY, sponsorImgWidth, sponsorImgHeight);
          doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
        };
        sponsorImg.onerror = () => {
          // If sponsor image fails to load, just save without it
          doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
        };
      } else {
        doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
      }
    };

    // Fallback if background image fails to load
    img.onerror = () => {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(13);
      doc.text("Could not load background image!", 50, 50);
      doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
    };
  };

  if (!donation) {
    return (
      <div className="p-4">
        <h1 className="text-xl font-bold">No donation found</h1>
        <p>Please donate first.</p>
      </div>
    );
  }

  return (
    <div className="my-8 flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-4">Donation Receipt Preview</h1>
      {/* On-Screen Preview */}
      <div
        style={{
          width: DESIGN_WIDTH,
          height: DESIGN_HEIGHT,
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          border: "1px solid #ccc",
          boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
          position: "relative",
          backgroundImage: 'url("/images/reciept_image.jpg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Render each field at its absolute position */}
        {fields.map((field) => {
          let value = "";
          if (field.key === "receiptNumber") {
            value = donation.receiptNumber || "N/A";
          } else if (field.key === "referenceId") {
            value = donation.payment_id ? String(donation.payment_id) : "";
          } else if (field.key === "createdAt") {
            value = formatDate(donation.createdAt);
          } else if (field.key === "name") {
            value = donation.name || "N/A";
          } else if (field.key === "whatsapp") {
            value = donation.whatsapp || "N/A";
          } else if (field.key === "amount") {
            value = donation.amount ? String(donation.amount) : "";
          } else if (field.key === "amountInWords") {
            value = getAmountInWords();
          } else if (field.key === "note") {
            value = donation.note || "N/A";
          } else if (field.key === "aadhar") {
            value = donation.aadharCard ? String(donation.aadharCard) : "";
          } else if (field.key === "pan") {
            value = donation.panCard ? String(donation.panCard) : "";
          } else {
            // "mode" or any leftover
            value = field.label;
          }

          return (
            <div
              key={field.key}
              style={{
                position: "absolute",
                top: field.y,
                left: field.x,
                fontSize: "12px",
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          );
        })}

        {/* Overlay sponsor image at the bottom if available */}
        {sponsorImageUrl && (
          <img
            src={sponsorImageUrl}
            alt="Sponsor"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "200px",
              objectFit: "cover",
            }}
          />
        )}
      </div>

      {/* Download PDF Button */}
      <button
        onClick={handleDownloadPDF}
        className="mt-4 bg-[brown] text-white px-4 py-2 rounded hover:opacity-80"
      >
        Download PDF
      </button>
    </div>
  );
};

export default DonationReceipt;
