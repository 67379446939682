// frontend/src/components/SharedDonationReceipt.jsx

import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import { toWords } from "number-to-words";
import { QRCodeCanvas } from "qrcode.react";
import { fetchJSON, API_BASE } from "../api";  // <-- Now valid

// Receipt background images by donation type
const receiptImages = {
  cash: "/images/receipt_cash.jpg",
  cheque: "/images/receipt_cheque.jpg",
  upi: "/images/receipt_upi.jpg",
};

// Original design dimensions
const DESIGN_WIDTH = Math.round(2482 / 4);  // ~621
const DESIGN_HEIGHT = Math.round(3508 / 4); // ~877
const PDF_TEXT_OFFSET = 12;

// Field arrays for each donation type
const cashFields = [
  { key: "receiptNumber", label: "Receipt No",     x: 479, y: 183 },
  { key: "createdAt",     label: "Date",           x: 103, y: 183 },
  { key: "name",          label: "Name",           x: 160, y: 230 },
  { key: "city",          label: "City",           x: 160, y: 284 },
  { key: "phone",         label: "Mobile",         x: 160, y: 257 },
  { key: "paymentMode",   label: "Payment Mode",   x: 160, y: 364 },
  { key: "amount",        label: "Amount",         x: 160, y: 390 },
  { key: "amountInWords", label: "Amount in Words",x: 160, y: 417 },
  { key: "note",          label: "Note",           x: 160, y: 311 },
  { key: "aadhar",        label: "Aadhar",         x: 160, y: 338 },
  { key: "pan",           label: "PAN",            x: 399, y: 338 },
];

const chequeFields = [
  { key: "receiptNumber", label: "Receipt No",     x: 479, y: 183 },
  { key: "createdAt",     label: "Date",           x: 103, y: 183 },
  { key: "name",          label: "Name",           x: 160, y: 230 },
  { key: "city",          label: "City",           x: 160, y: 284 },
  { key: "phone",         label: "Mobile",         x: 160, y: 257 },
  { key: "paymentMode",   label: "Payment Mode",   x: 160, y: 364 },
  { key: "amount",        label: "Amount",         x: 160, y: 417 },
  { key: "bankName",      label: "Bank",           x: 350, y: 390 },
  { key: "chequeNumber",  label: "Cheque No",      x: 160, y: 390 },
  { key: "amountInWords", label: "Amount in Words",x: 160, y: 443 },
  { key: "note",          label: "Note",           x: 160, y: 311 },
  { key: "aadhar",        label: "Aadhar",         x: 160, y: 338 },
  { key: "pan",           label: "PAN",            x: 399, y: 338 },
];

const upiFields = [
  { key: "receiptNumber",    label: "Receipt No",        x: 479, y: 183 },
  { key: "createdAt",        label: "Date",              x: 103, y: 183 },
  { key: "name",             label: "Name",              x: 160, y: 230 },
  { key: "city",             label: "City",              x: 160, y: 284 },
  { key: "phone",            label: "Mobile",            x: 160, y: 257 },
  { key: "paymentMode",      label: "Payment Mode",      x: 160, y: 364 },
  { key: "upiTransactionId", label: "Reference ID",      x: 160, y: 390 },
  { key: "amount",           label: "Amount",            x: 160, y: 417 },
  { key: "amountInWords",    label: "Amount in Words",   x: 160, y: 443 },
  { key: "note",             label: "Note",              x: 160, y: 311 },
  { key: "aadhar",           label: "Aadhar",            x: 160, y: 338 },
  { key: "pan",              label: "PAN",               x: 399, y: 338 },
];

// Format date as DD/MM/YYYY
function formatDate(dateStr) {
  const date = new Date(dateStr);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}

const SharedDonationReceipt = () => {
  const [donation, setDonation] = useState(null);
  const [searchParams] = useSearchParams();
  const donationId = searchParams.get("id");
  const donationTypeParam = searchParams.get("type") || "cash";

  const [currentDonationType, setCurrentDonationType] = useState(donationTypeParam);
  const [selectedFields, setSelectedFields] = useState(cashFields);
  const [scale, setScale] = useState(1);

  // Sponsor image from the public route
  const [sponsorImageUrl, setSponsorImageUrl] = useState(null);

  const receiptImage = receiptImages[currentDonationType] || receiptImages.cash;
  const ratio = DESIGN_HEIGHT / DESIGN_WIDTH;

  // Recalculate scale on resize
  const calculateScale = useCallback(() => {
    const maxWidth = window.innerWidth * 0.95;
    const newScale = Math.min(maxWidth / DESIGN_WIDTH, 1);
    setScale(newScale);
  }, []);

  // Fetch sponsor from /api/sponsors/public
  const fetchSponsor = useCallback(async () => {
    try {
      // Using our new fetchJSON from api.js
      const sponsors = await fetchJSON("/api/sponsors/public");
      if (sponsors.length > 0) {
        // Provide fully qualified path
        setSponsorImageUrl(`${API_BASE}/uploads/sponsors/${sponsors[0].filename}`);
      }
    } catch (err) {
      console.error("Error fetching sponsor:", err);
    }
  }, []);

  // Fetch the offline donation from /api/users/cash-donation/:id
  const fetchDonation = useCallback(async () => {
    if (!donationId) return;
    try {
      const data = await fetchJSON(`/api/users/cash-donation/${donationId}`);
      setDonation(data);

      // donationType from the record
      const dType = data.donationType || "cash";
      setCurrentDonationType(dType);

      // Adjust the selected fields
      switch (dType) {
        case "cheque":
          setSelectedFields(chequeFields);
          break;
        case "upi":
          setSelectedFields(upiFields);
          break;
        default:
          setSelectedFields(cashFields);
          break;
      }
    } catch (err) {
      console.error("Error fetching donation:", err);
    }
  }, [donationId]);

  const getAmountInWords = () => {
    if (!donation?.amount) return "N/A";
    return `Rupees ${toWords(Number(donation.amount))} only`;
  };

  const handleDownloadPDF = () => {
    if (!donation) return;

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [DESIGN_WIDTH, DESIGN_HEIGHT],
    });

    const backgroundImg = new Image();
    backgroundImg.src = receiptImage;

    backgroundImg.onload = () => {
      doc.addImage(backgroundImg, "JPG", 0, 0, DESIGN_WIDTH, DESIGN_HEIGHT);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(17);
      doc.setTextColor(0, 0, 0);

      // Print fields
      selectedFields.forEach((field) => {
        let text = "";
        switch (field.key) {
          case "receiptNumber":
            text = donation.receiptNumber || "N/A";
            break;
          case "referenceId":
            text = donation.payment_id || "";
            break;
          case "upiTransactionId":
            text = donation.upiTransactionId || "";
            break;
          case "createdAt":
            text = formatDate(donation.createdAt);
            break;
          case "name":
            text = donation.name || "N/A";
            break;
          case "city":
            text = donation.city || "";
            break;
          case "phone":
            text = donation.phone || "N/A";
            break;
          case "paymentMode": {
            const volunteerName = donation.volunteer?.username || "";
            const upperMode = (donation.donationType || "cash").toUpperCase();
            text = volunteerName
              ? `${upperMode} - collected by ${volunteerName}`
              : upperMode;
            break;
          }
          case "amount":
            text = donation.amount ? String(donation.amount) : "";
            break;
          case "amountInWords":
            text = getAmountInWords();
            break;
          case "note":
            text = donation.note || "N/A";
            break;
          case "aadhar":
            text = donation.aadharCard || "";
            break;
          case "pan":
            text = donation.panCard || "";
            break;
          case "bankName":
            text = donation.bankName || "";
            break;
          case "chequeNumber":
            text = donation.chequeNumber || "";
            break;
          default:
            text = field.label;
        }
        doc.text(text, field.x, field.y + PDF_TEXT_OFFSET);
      });

      // If deleted, watermark
      if (donation.isDeleted) {
        doc.setFontSize(60);
        doc.setTextColor(255, 0, 0);
        doc.text("DELETED", DESIGN_WIDTH / 2, DESIGN_HEIGHT / 2, { align: "center" });
      }

      // Sponsor
      if (sponsorImageUrl) {
        const sponsorImg = new Image();
        sponsorImg.src = sponsorImageUrl;
        sponsorImg.onload = () => {
          const sponsorImgWidth = DESIGN_WIDTH;
          const sponsorImgHeight = 200;
          const sponsorY = DESIGN_HEIGHT - sponsorImgHeight;
          doc.addImage(
            sponsorImg,
            "JPG",
            0,
            sponsorY,
            sponsorImgWidth,
            sponsorImgHeight
          );
          doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
        };
        sponsorImg.onerror = () => {
          doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
        };
      } else {
        doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
      }
    };

    backgroundImg.onerror = () => {
      doc.setFontSize(13);
      doc.text("Could not load background image!", 50, 50);
      doc.save(`receipt_${donation.receiptNumber || "donation"}.pdf`);
    };
  };

  // This route itself can be the share link
  const shareableLink = `${window.location.origin}/receipt-share?id=${donation?._id}&type=${donation?.donationType || currentDonationType}`;

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareableLink);
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error(err);
      alert("Failed to copy link.");
    }
  };

  useEffect(() => {
    fetchDonation();
    fetchSponsor();
    calculateScale();

    window.addEventListener("resize", calculateScale);
    return () => {
      window.removeEventListener("resize", calculateScale);
    };
  }, [fetchDonation, fetchSponsor, calculateScale]);

  if (!donation) {
    return (
      <div className="p-4">
        <h1 className="text-xl font-bold">Loading receipt...</h1>
      </div>
    );
  }

  return (
    <div className="p-4 flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-4 text-center">
        Donation Receipt Preview
      </h1>

      {/* On-screen preview container */}
      <div
        style={{
          width: "100%",
          maxWidth: "600px",
          position: "relative",
          paddingBottom: `${ratio * 100}%`,
          overflow: "hidden",
          border: "1px solid #ccc",
          boxShadow: "0px 0px 8px rgba(0,0,0,0.3)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transformOrigin: "top left",
            width: `${DESIGN_WIDTH}px`,
            height: `${DESIGN_HEIGHT}px`,
            transform: `scale(${scale})`,
            backgroundImage: `url(${receiptImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {selectedFields.map((field) => {
            let value = "";
            switch (field.key) {
              case "receiptNumber":
                value = donation.receiptNumber || "N/A";
                break;
              case "referenceId":
                value = donation.payment_id || "";
                break;
              case "upiTransactionId":
                value = donation.upiTransactionId || "";
                break;
              case "createdAt":
                value = formatDate(donation.createdAt);
                break;
              case "name":
                value = donation.name || "N/A";
                break;
              case "city":
                value = donation.city || "";
                break;
              case "phone":
                value = donation.phone || "N/A";
                break;
              case "paymentMode": {
                const volunteerName = donation.volunteer?.username || "";
                const upperMode = (donation.donationType || "cash").toUpperCase();
                value = volunteerName
                  ? `${upperMode} - collected by ${volunteerName}`
                  : upperMode;
                break;
              }
              case "amount":
                value = donation.amount ? String(donation.amount) : "";
                break;
              case "amountInWords":
                value = getAmountInWords();
                break;
              case "note":
                value = donation.note || "N/A";
                break;
              case "aadhar":
                value = donation.aadharCard || "";
                break;
              case "pan":
                value = donation.panCard || "";
                break;
              case "bankName":
                value = donation.bankName || "";
                break;
              case "chequeNumber":
                value = donation.chequeNumber || "";
                break;
              default:
                value = field.label;
            }
            return (
              <div
                key={field.key}
                style={{
                  position: "absolute",
                  top: field.y,
                  left: field.x,
                  fontSize: "13px",
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                }}
              >
                {value}
              </div>
            );
          })}
          {/* Sponsor overlay in preview */}
          {sponsorImageUrl && (
            <img
              src={sponsorImageUrl}
              alt="Sponsor"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "200px",
                objectFit: "cover",
              }}
            />
          )}
        </div>
      </div>

      {/* Shareable link & QR */}
      <div className="mt-4 w-full max-w-md p-4 border rounded shadow">
        <h2 className="text-lg font-bold mb-2 text-center">
          Shareable Receipt Link
        </h2>
        <input
          type="text"
          readOnly
          value={shareableLink}
          className="w-full p-2 border rounded mb-2"
        />
        <button
          onClick={copyLink}
          className="w-full bg-primary text-white py-2 rounded mb-4 hover:opacity-90"
        >
          Copy Link
        </button>
        <div className="flex justify-center">
          <QRCodeCanvas value={shareableLink} size={128} />
        </div>
      </div>

      {/* Button to download PDF */}
      <button
        onClick={handleDownloadPDF}
        className="mt-4 bg-[brown] text-white px-4 py-2 rounded hover:bg-opacity-80"
      >
        Download PDF
      </button>
    </div>
  );
};

export default SharedDonationReceipt;
