// // frontend/src/components/Header.jsx
// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import DonateModal from './DonateModal';
// // React Icons
// import {
//   FaEnvelope,
//   FaPhoneAlt,
//   FaFacebookF,
//   FaTwitter,
//   FaGooglePlusG,
//   FaLinkedinIn,
// } from 'react-icons/fa';

// const Header = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [showDonateModal, setShowDonateModal] = useState(false);

//   // Social media items for top-right
//   const socialLinks = [
//     { icon: <FaFacebookF />, url: '#', label: 'Facebook' },
//     { icon: <FaTwitter />, url: '#', label: 'Twitter' },
//     { icon: <FaGooglePlusG />, url: '#', label: 'Google Plus' },
//     { icon: <FaLinkedinIn />, url: '#', label: 'LinkedIn' },
//   ];

//   return (
//     <header className="shadow-md font-sans">
//       {/* Top Header */}
//       <div className="bg-secondary text-dark text-sm">
//         <div className="container mx-auto px-4 py-2 flex justify-between items-center">
//           {/* Left: Email + Phone */}
//           <ul className="flex space-x-6 items-center">
//             <li className="flex items-center space-x-1 hover:text-primary transition-colors">
//               <FaEnvelope />
//               <a href="mailto:jivadayamandalbhachau@yahoo.com">
//                 jivadayamandalbhachau@yahoo.com
//               </a>
//             </li>
//             <li className="flex items-center space-x-1 hover:text-primary transition-colors">
//               <FaPhoneAlt />
//               <a href="tel:+919925042930">+91 99250 42930</a>
//             </li>
//           </ul>

//           {/* Right: Social Icons */}
//           <ul className="flex space-x-4 items-center">
//             {socialLinks.map((item, idx) => (
//               <li key={idx}>
//                 <a
//                   href={item.url}
//                   target="_blank"
//                   rel="noreferrer"
//                   aria-label={item.label}
//                   className="text-dark hover:text-primary transition-colors text-xl"
//                 >
//                   {item.icon}
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>

//       {/* Main Navigation */}
//       <div className="bg-white">
//         <div className="container mx-auto px-4 py-3 flex justify-between items-center">
//           {/* Logo */}
//           <div className="flex-shrink-0">
//             <NavLink to="/" className="flex items-center space-x-2">
//               <img src="/images/logo.png" alt="Logo" className="h-12" />
//             </NavLink>
//           </div>

//           {/* Desktop Menu */}
//           <nav className="hidden md:flex space-x-8 items-center">
//             <NavLink
//               to="/about"
//               className="text-dark font-semibold hover:text-primary transition-colors"
//             >
//               JivDaya Trust
//             </NavLink>
//             <NavLink
//               to="/project"
//               className="text-dark font-semibold hover:text-primary transition-colors"
//             >
//               Project
//             </NavLink>
//             <NavLink
//               to="/gallery"
//               className="text-dark font-semibold hover:text-primary transition-colors"
//             >
//               Gallery
//             </NavLink>
//             <NavLink
//               to="/donation-centres"
//               className="text-dark font-semibold hover:text-primary transition-colors"
//             >
//               Donation Centres
//             </NavLink>
//             <NavLink
//               to="/bank-details"
//               className="text-dark font-semibold hover:text-primary transition-colors"
//             >
//               Bank Details
//             </NavLink>
//             <NavLink
//               to="/contact"
//               className="text-dark font-semibold hover:text-primary transition-colors"
//             >
//               Contact Us
//             </NavLink>
//             <button
//               onClick={() => setShowDonateModal(true)}
//               className="bg-gradient-to-r from-primary to-secondary text-white px-4 py-2 rounded hover:opacity-90 transition-opacity"
//             >
//               Donate Now
//             </button>
//           </nav>

//           {/* Mobile Menu Toggle */}
//           <div className="md:hidden">
//             <button
//               onClick={() => setIsOpen(!isOpen)}
//               className="focus:outline-none text-dark"
//               aria-label="Toggle Menu"
//             >
//               {/* Hamburger / Close Icon */}
//               <svg className="h-6 w-6" fill="none" stroke="currentColor">
//                 {isOpen ? (
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M6 18L18 6M6 6l12 12"
//                   />
//                 ) : (
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M4 8h16M4 16h16"
//                   />
//                 )}
//               </svg>
//             </button>
//           </div>
//         </div>

//         {/* Mobile Menu */}
//         {isOpen && (
//           <div className="md:hidden bg-secondary">
//             <nav className="container mx-auto px-4 py-4">
//               <ul className="flex flex-col space-y-4 text-dark">
//                 <li>
//                   <NavLink
//                     to="/about"
//                     onClick={() => setIsOpen(false)}
//                     className="font-semibold hover:text-primary transition-colors"
//                   >
//                     JivDaya Trust
//                   </NavLink>
//                 </li>
//                 <li>
//                   <NavLink
//                     to="/project"
//                     onClick={() => setIsOpen(false)}
//                     className="font-semibold hover:text-primary transition-colors"
//                   >
//                     Project
//                   </NavLink>
//                 </li>
//                 <li>
//                   <NavLink
//                     to="/gallery"
//                     onClick={() => setIsOpen(false)}
//                     className="font-semibold hover:text-primary transition-colors"
//                   >
//                     Gallery
//                   </NavLink>
//                 </li>
//                 <li>
//                   <NavLink
//                     to="/donation-centres"
//                     onClick={() => setIsOpen(false)}
//                     className="font-semibold hover:text-primary transition-colors"
//                   >
//                     Donation Centres
//                   </NavLink>
//                 </li>
//                 <li>
//                   <NavLink
//                     to="/bank-details"
//                     onClick={() => setIsOpen(false)}
//                     className="font-semibold hover:text-primary transition-colors"
//                   >
//                     Bank Details
//                   </NavLink>
//                 </li>
//                 <li>
//                   <NavLink
//                     to="/contact"
//                     onClick={() => setIsOpen(false)}
//                     className="font-semibold hover:text-primary transition-colors"
//                   >
//                     Contact Us
//                   </NavLink>
//                 </li>
//                 <li>
//                   <button
//                     onClick={() => {
//                       setShowDonateModal(true);
//                       setIsOpen(false);
//                     }}
//                     className="bg-gradient-to-r from-primary to-secondary text-white px-4 py-2 rounded hover:opacity-90 transition-opacity"
//                   >
//                     Donate Now
//                   </button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         )}
//       </div>

//       {/* Render Donate Modal */}
//       {showDonateModal && <DonateModal onClose={() => setShowDonateModal(false)} />}
//     </header>
//   );
// };

// export default Header;


// frontend/src/components/Header.jsx
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import DonateModal from './DonateModal';
import {
  FaEnvelope,
  FaPhoneAlt,
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
} from 'react-icons/fa';

function formatIndianPhoneDisplay(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  if (digits.length === 12 && digits.startsWith("91")) {
    const country = digits.slice(0, 2);
    const part1 = digits.slice(2, 7);
    const part2 = digits.slice(7);
    return `+${country} ${part1} ${part2}`;
  }
  return `+${digits}`;
}

function formatIndianPhoneLink(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  return digits;
}

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDonateModal, setShowDonateModal] = useState(false);

  // Updated social links: Only Facebook, WhatsApp, and Instagram
  const socialLinks = [
    { icon: <FaFacebookF />, url: '#', label: 'Facebook' },
    { icon: <FaWhatsapp />, url: '#', label: 'WhatsApp' },
    { icon: <FaInstagram />, url: '#', label: 'Instagram' },
  ];

  const phoneNumberRaw = "9925042930";

  return (
    <header className="shadow-md font-sans">
      {/* Top Header */}
      <div className="bg-secondary text-dark text-sm">
        <div className="container mx-auto px-4 py-2 flex flex-col md:flex-row items-center justify-between gap-y-2">
          {/* Left: Email + Phone */}
          <ul className="flex flex-wrap space-x-6 items-center justify-center md:justify-start">
            <li className="flex items-center space-x-1 hover:text-primary transition-colors">
              <FaEnvelope />
              <a href="mailto:jivadayamandalbhachau@yahoo.com" className="truncate">
                jivadayamandalbhachau@yahoo.com
              </a>
            </li>
            <li className="flex items-center space-x-1 hover:text-primary transition-colors">
              <FaPhoneAlt />
              <a
                href={`tel:+${formatIndianPhoneLink(phoneNumberRaw)}`}
                className="truncate"
              >
                {formatIndianPhoneDisplay(phoneNumberRaw)}
              </a>
            </li>
          </ul>

          {/* Right: Social Icons */}
          <ul className="flex space-x-4 items-center justify-center md:justify-end">
            {socialLinks.map((item, idx) => (
              <li key={idx}>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={item.label}
                  className="text-dark hover:text-primary transition-colors text-xl"
                >
                  {item.icon}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Main Navigation */}
      <div className="bg-white">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          {/* Logo */}
          <div className="flex-shrink-0">
            <NavLink to="/" className="flex items-center space-x-2">
              <img src="/images/logo.png" alt="Logo" className="h-12" />
            </NavLink>
          </div>

          {/* Desktop Menu */}
          <nav className="hidden md:flex space-x-8 items-center">
            <NavLink
              to="/about"
              className="text-dark font-semibold hover:text-primary transition-colors"
            >
              Shri JivDaya Mandal Bhachau
            </NavLink>
            <NavLink
              to="/project"
              className="text-dark font-semibold hover:text-primary transition-colors"
            >
              Project
            </NavLink>
            <NavLink
              to="/gallery"
              className="text-dark font-semibold hover:text-primary transition-colors"
            >
              Gallery
            </NavLink>
            <NavLink
              to="/donation-centres"
              className="text-dark font-semibold hover:text-primary transition-colors"
            >
              Donation Centres
            </NavLink>
            <NavLink
              to="/bank-details"
              className="text-dark font-semibold hover:text-primary transition-colors"
            >
              Bank Details
            </NavLink>

            {/* NEW LINK: Terms & Refund */}
            <NavLink
              to="/terms-and-refund"
              className="text-dark font-semibold hover:text-primary transition-colors"
            >
              Terms &amp; Refund
            </NavLink>

            <NavLink
              to="/contact"
              className="text-dark font-semibold hover:text-primary transition-colors"
            >
              Contact Us
            </NavLink>

            <button
              onClick={() => setShowDonateModal(true)}
              className="bg-gradient-to-r from-primary to-secondary text-white px-4 py-2 rounded hover:opacity-90 transition-opacity"
            >
              Donate Now
            </button>
          </nav>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="focus:outline-none text-dark"
              aria-label="Toggle Menu"
            >
              {/* Hamburger / Close Icon */}
              <svg className="h-6 w-6" fill="none" stroke="currentColor">
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 8h16M4 16h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden bg-secondary">
            <nav className="container mx-auto px-4 py-4">
              <ul className="flex flex-col space-y-4 text-dark">
                <li>
                  <NavLink
                    to="/about"
                    onClick={() => setIsOpen(false)}
                    className="font-semibold hover:text-primary transition-colors"
                  >
                    Shri JivDaya Mandal Bhachau
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/project"
                    onClick={() => setIsOpen(false)}
                    className="font-semibold hover:text-primary transition-colors"
                  >
                    Project
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/gallery"
                    onClick={() => setIsOpen(false)}
                    className="font-semibold hover:text-primary transition-colors"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/donation-centres"
                    onClick={() => setIsOpen(false)}
                    className="font-semibold hover:text-primary transition-colors"
                  >
                    Donation Centres
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/bank-details"
                    onClick={() => setIsOpen(false)}
                    className="font-semibold hover:text-primary transition-colors"
                  >
                    Bank Details
                  </NavLink>
                </li>
                {/* Mobile: Terms & Refund */}
                <li>
                  <NavLink
                    to="/terms-and-refund"
                    onClick={() => setIsOpen(false)}
                    className="font-semibold hover:text-primary transition-colors"
                  >
                    Terms &amp; Refund
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    onClick={() => setIsOpen(false)}
                    className="font-semibold hover:text-primary transition-colors"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={() => {
                      setShowDonateModal(true);
                      setIsOpen(false);
                    }}
                    className="bg-gradient-to-r from-primary to-secondary text-white px-4 py-2 rounded hover:opacity-90 transition-opacity"
                  >
                    Donate Now
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>

      {/* Render Donate Modal */}
      {showDonateModal && <DonateModal onClose={() => setShowDonateModal(false)} />}
    </header>
  );
};

export default Header;
