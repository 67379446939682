// src/components/Gallery.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Gallery = () => {
  return (
    <main className="bg-white">
      {/* Banner / Hero Section */}
      <section
        className="relative bg-center bg-cover h-72 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/gallery_banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-4xl font-bold">Our Photos</h1>
        </div>
      </section>

      {/* Main Gallery Section */}
      <section id="portfolio" className="py-16">
        <div className="container mx-auto px-4">
          {/* Section Header */}
          <div className="mb-8 text-center">
            <p className="text-dark">
              <span className="text-secondary mr-2">Check our</span>
            </p>
            <h1 className="text-3xl font-bold text-primary">Gallery</h1>
          </div>

          {/* Gallery Links */}
          <div className="flex flex-col md:flex-row justify-center gap-12">
            {/* Infrastructure */}
            <Link
              to="/infrastructure"
              className="block group w-full md:w-1/3 transform hover:scale-105 transition-transform"
            >
              <div className="bg-white p-4 rounded shadow hover:shadow-lg transition-shadow">
                <img
                  src="/images/infrastructure.webp"
                  alt="Infrastructure"
                  className="w-full h-56 object-cover"
                />
                <div className="mt-2 text-center text-dark font-semibold text-lg">
                  Infrastructure
                </div>
              </div>
            </Link>

            {/* 3D Photo */}
            <Link
              to="/3d-photo"
              className="block group w-full md:w-1/3 transform hover:scale-105 transition-transform"
            >
              <div className="bg-white p-4 rounded shadow hover:shadow-lg transition-shadow">
                <img
                  src="/images/3d-photo.webp"
                  alt="3D Photo"
                  className="w-full h-56 object-cover"
                />
                <div className="mt-2 text-center text-dark font-semibold text-lg">
                  3D Photo
                </div>
              </div>
            </Link>

            {/* Animal Help */}
            <Link
              to="/animal-help"
              className="block group w-full md:w-1/3 transform hover:scale-105 transition-transform"
            >
              <div className="bg-white p-4 rounded shadow hover:shadow-lg transition-shadow">
                <img
                  src="/images/animal-help.webp"
                  alt="Animal Help"
                  className="w-full h-56 object-cover"
                />
                <div className="mt-2 text-center text-dark font-semibold text-lg">
                  Animal Help
                </div>
              </div>
            </Link>
          </div>

          {/* Embedded Video (Example) */}
          <div className="mt-12">
            <video
              width="100%"
              height="500"
              controls
              src="/images/animal-help/Video1.mp4"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Gallery;
