// src/components/Project.jsx
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import React Icons
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaUser, FaComment } from 'react-icons/fa';

function formatIndianPhoneDisplay(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  if (digits.length === 12 && digits.startsWith("91")) {
    const country = digits.slice(0, 2);
    const part1 = digits.slice(2, 7);
    const part2 = digits.slice(7);
    return `+${country} ${part1} ${part2}`;
  }
  return `+${digits}`;
}

function formatIndianPhoneLink(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  return digits;
}

const Project = () => {
  // Main slider & thumbnail slider refs
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  // Large slider settings
  const mainSliderSettings = {
    asNavFor: nav2,
    ref: slider1,
    arrows: false,
    fade: true,
  };

  // Thumbnail slider settings
  const thumbSliderSettings = {
    asNavFor: nav1,
    ref: slider2,
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
  };

  // Data for images in the blueprint / project plan
  const blueprintSlides = [
    { large: '/images/project/project_lg-1.jpg', thumb: '/images/project/project-1.jpg', title: 'Nayana Nandi Map' },
    { large: '/images/project/project_lg-2.jpg', thumb: '/images/project/project-2.jpg', title: 'Pashu Aavas' },
    { large: '/images/project/project_lg-3.jpg', thumb: '/images/project/project-3.jpg', title: 'Ground Floor' },
    { large: '/images/project/project_lg-4.jpg', thumb: '/images/project/project-4.jpg', title: 'First Floor' },
    { large: '/images/project/project_lg-5.jpg', thumb: '/images/project/project-5.jpg', title: 'Staff Quarter' },
    { large: '/images/project/project_lg-6.jpg', thumb: '/images/project/project-6.jpg', title: 'Pashu Sevak Aavas' },
    { large: '/images/project/project_lg-7.jpg', thumb: '/images/project/project-7.jpg', title: 'Ghas Bhandar' },
    { large: '/images/project/project_lg-8.jpg', thumb: '/images/project/project-8.jpg', title: 'Pakshi Ghar & Hospital' },
    { large: '/images/project/project_lg-9.jpg', thumb: '/images/project/project-9.jpg', title: 'CCTV Camera & Pashu Gruh' },
    { large: '/images/project/project_lg-10.jpg', thumb: '/images/project/project-10.jpg', title: 'Playing Area' },
  ];

  return (
    <main className="bg-white">
      {/* Banner / Hero Section */}
      <section
        className="relative bg-center bg-cover h-72 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/project_banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-4xl font-bold">Our Project</h1>
        </div>
      </section>

      {/* Project Plan Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-primary">Project Plan</h1>
            <p className="text-dark">See our working project blueprint</p>
          </div>
          {/* Slider Block */}
          <div>
            {/* Main Slider */}
            <Slider {...mainSliderSettings}>
              {blueprintSlides.map((item, idx) => (
                <div key={idx} className="relative">
                  <img
                    src={item.large}
                    alt={item.title}
                    className="w-full h-96 object-cover"
                  />
                  <div className="bg-black bg-opacity-60 text-white px-4 py-2 absolute bottom-0 left-0">
                    <h2 className="text-xl">{item.title}</h2>
                  </div>
                </div>
              ))}
            </Slider>

            {/* Thumbnail Slider */}
            <div className="mt-4">
              <Slider {...thumbSliderSettings}>
                {blueprintSlides.map((item, idx) => (
                  <div key={idx} className="px-1">
                    <img
                      src={item.thumb}
                      alt={item.title}
                      className="w-full h-20 object-cover border border-gray-300"
                    />
                    <p className="text-center text-sm text-dark mt-1">
                      {item.title}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      {/* CallOut Section */}
      <section className="bg-gray-200 py-16">
        <div className="container mx-auto px-4 text-center">
          <h3 className="text-3xl font-bold text-primary mb-4">
            Become <span className="text-secondary">Volunteer</span>
          </h3>
          <p className="text-dark mb-6">
            Your Voluntary Contribution Is Worth More Than Money
          </p>
          <a
            href="#"
            className="bg-primary text-white px-8 py-3 rounded-full hover:bg-secondary transition-colors"
          >
            Join Today
          </a>
        </div>
      </section>
    </main>
  );
};

export default Project;
