// frontend/src/components/Home.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // <-- Import Link from react-router-dom
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SplitText from './SplitText';
import { FaHandHoldingHeart, FaCamera } from 'react-icons/fa';
import { GiDove } from 'react-icons/gi';
import { motion } from 'framer-motion';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const Home = () => {
  // ======================
  // Hero Slider Settings
  // ======================
  const heroSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const bannerSlides = [
    {
      img: '/images/banner/banner1.jpg',
      subtitle: 'Helping animals by donating us!!',
      title: 'Change an Animal’s Life',
    },
    {
      img: '/images/banner/banner2.jpg',
      subtitle: 'Helping animals by donating us!!',
      title: 'Change an Animal’s Life',
    },
    {
      img: '/images/banner/banner3.jpg',
      subtitle: 'Helping animals by donating us!!',
      title: 'Change an Animal’s Life',
    },
  ];

  // ======================
  // Entry Gate Sliders (Main + Thumbnails)
  // ======================
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  // Track current slide index to show dynamic caption
  const [entryIndex, setEntryIndex] = useState(0);

  const entrySlides = [
    {
      main: '/images/about/about_lg-1.jpg',
      thumb: '/images/about/about_thumb-1.jpg',
      caption: 'Entry Gate',
    },
    {
      main: '/images/about/about_lg-2.jpg',
      thumb: '/images/about/about_thumb-2.jpg',
      caption: 'Top View',
    },
    {
      main: '/images/about/about_lg-3.jpg',
      thumb: '/images/about/about_thumb-3.jpg',
      caption: 'Hospital & Staff Quarters',
    },
    {
      main: '/images/about/about_lg-4.jpg',
      thumb: '/images/about/about_thumb-4.jpg',
      caption: 'Office',
    },
    {
      main: '/images/about/about_lg-5.jpg',
      thumb: '/images/about/about_thumb-5.jpg',
      caption: 'Pigeon-Tower',
    },
  ];

  // Main slider settings
  const mainSliderSettings = {
    asNavFor: nav2,
    ref: slider1,
    arrows: false,
    fade: true,
    beforeChange: (oldIndex, newIndex) => setEntryIndex(newIndex),
  };

  // Thumbnail slider settings
  const thumbSliderSettings = {
    asNavFor: nav1,
    ref: slider2,
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
  };

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  // ======================
  // About Cards Data
  // ======================
  const aboutCards = [
    {
      title: 'Trusti Mandal',
      icon: <FaHandHoldingHeart size={40} className="text-primary" />,
      link: '/trusti-mandal',
      description:
        'Our Trusti Mandal is dedicated to community guidance and ensuring animal welfare with compassion.',
    },
    {
      title: 'Ahinsadham',
      icon: <GiDove size={40} className="text-primary" />,
      link: '/about',
      description:
        'Ahinsadham represents our commitment to non-violence and peace in every action we take.',
    },
    {
      title: 'Photo Gallery',
      icon: <FaCamera size={40} className="text-primary" />,
      link: '/gallery',
      description:
        'Browse our captivating photo gallery that captures heartwarming moments of animal rescue.',
    },
  ];

  // ======================
  // Impact Stats Data
  // ======================
  const impactStats = [
    { stat: '52', label: 'Years of Service' },
    { stat: '1600+', label: 'Animals Helped' },
    { stat: '500+', label: 'Volunteers' },
    { stat: '100+', label: 'Rescues' },
  ];

  return (
    <main className="bg-white font-sans">
      {/* =========================
          Hero Slider Section
      ========================== */}
      <motion.section
        className="relative"
        variants={fadeInUp}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.6 }}
      >
        <Slider {...heroSettings}>
          {bannerSlides.map((slide, idx) => (
            <div key={idx} className="relative w-full h-80">
              <img
                src={slide.img}
                alt={`Banner ${idx + 1}`}
                className="w-full h-full object-cover"
              />
              {/* Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center px-4">
                {/* Subtitle */}
                <div className="mb-2">
                  <SplitText
                    text={slide.subtitle}
                    className="inline-block bg-primary bg-opacity-75 text-white px-4 py-2 text-sm md:text-base font-medium"
                    delay={30}
                    animationFrom={{ opacity: 0, transform: 'translate3d(0,20px,0)' }}
                    animationTo={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
                    easing="easeOutCubic"
                    threshold={0.1}
                    rootMargin="-50px"
                  />
                </div>
                {/* Title */}
                <div>
                  <SplitText
                    text={slide.title}
                    className="block bg-dark bg-opacity-75 text-white px-4 py-2 text-2xl md:text-4xl font-semibold"
                    delay={50}
                    animationFrom={{ opacity: 0, transform: 'translate3d(0,40px,0)' }}
                    animationTo={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
                    easing="easeOutCubic"
                    threshold={0.1}
                    rootMargin="-50px"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </motion.section>

      {/* =========================
          About Section
          (Using a soft gray background)
      ========================== */}
      <motion.section
        className="py-16 bg-gray-100"
        variants={fadeInUp}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <div className="container mx-auto px-4 text-center">
          <div className="mb-8">
            <p className="text-lg text-dark font-semibold">Who We Are</p>
            <h1 className="text-3xl font-bold text-primary mt-2">
              <span className="font-bold">
                Shri Jivdaya Mandal Bhachau - managed by Shri Kanaksuri Ahinsa Dham Bhachau
              </span>
            </h1>
            <p className="text-dark mt-2 text-xl">
              Trust Register Number E-722 (Kutch), Established on 11/11/1971
            </p>
          </div>
          <div className="max-w-2xl mx-auto text-dark text-lg">
            <p className="mb-4 font-semibold">
              Shri Jivdaya Mandal Bhachau warmly welcomes you all animal lovers. We
              have been serving with full dedication for over 52 years, helping over
              1600 animals.
            </p>
            <p className="mb-4">
              We help injured, homeless, and unhealthy animals. Our commitment extends
              beyond sheltering; we educate and empower communities to foster a
              compassionate environment.
            </p>
            <a
              href="/about"
              className="text-primary underline hover:text-secondary transition-colors text-xl font-medium"
            >
              Read Full Story
            </a>
          </div>

          {/* About Cards */}
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-3 gap-6">
            {aboutCards.map((card, index) => (
              <motion.div
                key={index}
                className="bg-white shadow rounded-lg p-6 transition transform hover:scale-105"
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <div className="mb-4 flex justify-center">{card.icon}</div>
                <h3 className="text-xl font-bold text-dark mb-2">{card.title}</h3>
                <p className="text-dark mb-4 text-sm">{card.description}</p>
                <a
                  href={card.link}
                  className="text-primary underline hover:text-secondary transition-colors font-medium"
                >
                  Learn More
                </a>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* =========================
          Our Impact Section – Interactive Stats
          (Using a contrasting background color)
      ========================== */}
      <motion.section
        className="py-16 bg-gray-200"
        variants={fadeInUp}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-primary mb-4">Our Impact</h2>
          <p className="text-dark mb-8 max-w-xl mx-auto text-lg">
            Every effort counts. Over the decades, our work has transformed countless
            lives, and our impact continues to grow as more hearts join our mission.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
            {impactStats.map((item, index) => (
              <motion.div
                key={index}
                className="bg-white shadow rounded-lg p-6 transition transform hover:scale-105"
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.6, delay: 0.1 * index }}
              >
                <h3 className="text-4xl font-bold text-primary mb-2">{item.stat}</h3>
                <p className="text-dark text-lg">{item.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* =========================
          Entry Gate Gallery (Synced Sliders)
          (Using a clean white background)
      ========================== */}
      <motion.section
        className="py-16 bg-white"
        variants={fadeInUp}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.6, delay: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-primary">Entry Gate</h1>
            <p className="text-dark text-xl">BHACHAU JIVDAYA TRUST</p>
          </div>

          {/* Main Slider */}
          <Slider {...mainSliderSettings}>
            {entrySlides.map((slide, index) => (
              <div key={index}>
                <img
                  src={slide.main}
                  alt={slide.caption}
                  className="w-full max-h-[500px] object-contain mx-auto"
                />
              </div>
            ))}
          </Slider>

          {/* Dynamic Caption */}
          <div className="text-center mt-2">
            <h2 className="text-xl font-semibold text-dark">
              {entrySlides[entryIndex].caption}
            </h2>
          </div>

          {/* Thumbnail Slider */}
          <div className="mt-4">
            <Slider {...thumbSliderSettings}>
              {entrySlides.map((item, index) => (
                <div key={index} className="px-1">
                  <img
                    src={item.thumb}
                    alt={item.caption}
                    className="w-full h-20 object-cover border border-gray-300 transition-transform hover:scale-105"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </motion.section>

      {/* =========================
          Callout Section – Volunteer
          (Using the secondary color background)
      ========================== */}
      <motion.section
        className="bg-secondary py-16"
        variants={fadeInUp}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.6, delay: 1.0 }}
      >
        <div className="container mx-auto px-4 text-center">
          <h3 className="text-3xl font-bold text-dark mb-4">
            Become <span className="text-primary">Volunteer</span>
          </h3>
          <p className="text-dark mb-6 max-w-xl mx-auto text-xl">
            Your Voluntary Contribution Is Worth More Than Money
          </p>

          {/* 
            Updated button to link to /contact.
            If using plain anchor: <a href="/contact" ...>
            If using React Router, use <Link> 
          */}
          <Link
            to="/contact"
            className="bg-dark text-white px-8 py-3 rounded-full hover:bg-primary transition-colors text-xl font-semibold"
          >
            Join Today
          </Link>
        </div>
      </motion.section>
    </main>
  );
};

export default Home;
