// frontend/src/components/Footer.jsx
import React from 'react';
import {
  FaEnvelope,
  FaPhoneAlt,
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
} from 'react-icons/fa';

// Helper functions to format phone number as +91 99999 99999:
function formatIndianPhoneDisplay(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  if (digits.length === 12 && digits.startsWith("91")) {
    const country = digits.slice(0, 2);
    const part1 = digits.slice(2, 7);
    const part2 = digits.slice(7);
    return `+${country} ${part1} ${part2}`;
  }
  return `+${digits}`;
}

function formatIndianPhoneLink(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  return digits;
}

const Footer = () => {
  // Example phone number stored raw (without spaces)
  const phoneNumberRaw = "9925042930"; 
  const displayPhone = formatIndianPhoneDisplay(phoneNumberRaw);
  const callPhoneLink = `tel:+${formatIndianPhoneLink(phoneNumberRaw)}`;

  // Example email
  const emailAddress = "jivadayamandalbhachau@yahoo.com";

  // Social links for Facebook, Instagram, WhatsApp only
  // Replace '#' with real URLs if you have them
  const socialLinks = [
    { icon: <FaFacebookF />, url: "#", label: 'Facebook' },
    { icon: <FaInstagram />, url: "#", label: 'Instagram' },
    {
      icon: <FaWhatsapp />,
      // For a WhatsApp link:
      url: `https://wa.me/${formatIndianPhoneLink(phoneNumberRaw)}?text=Hi`,
      label: 'WhatsApp'
    },
  ];

  return (
    <footer className="bg-gray-100 border-t border-secondary py-6">
      <div className="container mx-auto px-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        {/* Left Section */}
        <div className="flex flex-col items-start md:items-start space-y-2">
          <div className="text-sm text-dark">
            &copy; 2025 <strong>Shri Jivdaya Mandal</strong> - All Rights Reserved
          </div>
          {/* Email & Phone */}
          <ul className="flex flex-col space-y-1">
            {/* Email */}
            <li className="flex items-center space-x-1">
              <FaEnvelope className="text-primary" />
              <a href={`mailto:${emailAddress}`} className="text-dark hover:text-primary">
                {emailAddress}
              </a>
            </li>
            {/* Phone */}
            <li className="flex items-center space-x-1">
              <FaPhoneAlt className="text-primary" />
              <a href={callPhoneLink} className="text-dark hover:text-primary">
                {displayPhone}
              </a>
            </li>
            {/* Terms & Refund Policy */}
            <li>
              <a
                href="/terms-and-refund"
                className="text-dark hover:text-primary font-semibold"
              >
                Terms &amp; Refund Policy
              </a>
            </li>
            {/* Developed by Aivia Labs */}
            <li>
              <a
                href="https://stockrizz.aivialabs.com/"
                target="_blank"
                rel="noreferrer"
                className="text-dark hover:text-primary font-semibold"
              >
                Developed by Aivia Labs
              </a>
            </li>
          </ul>
        </div>

        {/* Right Section: Social Icons */}
        <div className="flex space-x-3">
          {socialLinks.map((item, idx) => (
            <a
              key={idx}
              href={item.url}
              target="_blank"
              rel="noreferrer"
              aria-label={item.label}
              className="bg-dark text-white p-2 rounded-full hover:bg-primary transition-colors"
            >
              {item.icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
