// // src/components/Contact.jsx
// import React, { useState } from 'react';
// import {
//   FaUser,
//   FaGlobe,
//   FaEnvelope,
//   FaPhoneAlt,
//   FaComment,
//   FaMapMarkerAlt,
//   FaWhatsapp,
// } from 'react-icons/fa';

// const Contact = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     country: '',
//     email: '',
//     phone: '',
//     comments: '',
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.id]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Here you can integrate your form submission logic (e.g., send to backend)
//     alert('Form submitted!');
//     setFormData({
//       name: '',
//       country: '',
//       email: '',
//       phone: '',
//       comments: '',
//     });
//   };

//   return (
//     <main className="bg-white">
//       {/* =====================
//           Banner Section
//       ===================== */}
//       <section
//         className="relative bg-center bg-cover h-72 flex items-center justify-center"
//         style={{ backgroundImage: "url('/images/contact_banner.jpg')" }}
//       >
//         <div className="bg-black bg-opacity-40 absolute inset-0" />
//         <div className="relative z-10 text-center text-white">
//           <h1 className="text-4xl font-bold">Contact Us</h1>
//         </div>
//       </section>

//       {/* =====================
//           Contact Content Section
//       ===================== */}
//       <section className="py-16">
//         <div className="container mx-auto px-4">
//           <div className="flex flex-col md:flex-row gap-8">
//             {/* Left Column: Contact Form */}
//             <div className="md:w-1/2">
//               <h2 className="text-2xl font-bold text-primary mb-4">Contact Us</h2>
//               <form onSubmit={handleSubmit} className="space-y-4">
//                 <div className="flex flex-col md:flex-row gap-4">
//                   {/* Name & Country */}
//                   <div className="flex-1">
//                     {/* Name */}
//                     <div className="relative">
//                       <FaUser className="absolute top-3 left-3 text-primary" />
//                       <input
//                         type="text"
//                         id="name"
//                         placeholder="Name"
//                         value={formData.name}
//                         onChange={handleChange}
//                         className="w-full pl-10 p-3 border rounded focus:outline-none"
//                         required
//                       />
//                     </div>
//                     {/* Country */}
//                     <div className="relative mt-4">
//                       <FaGlobe className="absolute top-3 left-3 text-primary" />
//                       <input
//                         type="text"
//                         id="country"
//                         placeholder="Country"
//                         value={formData.country}
//                         onChange={handleChange}
//                         className="w-full pl-10 p-3 border rounded focus:outline-none"
//                       />
//                     </div>
//                   </div>

//                   {/* Email & Phone */}
//                   <div className="flex-1">
//                     {/* Email */}
//                     <div className="relative">
//                       <FaEnvelope className="absolute top-3 left-3 text-primary" />
//                       <input
//                         type="email"
//                         id="email"
//                         placeholder="Email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="w-full pl-10 p-3 border rounded focus:outline-none"
//                         required
//                       />
//                     </div>
//                     {/* Phone */}
//                     <div className="relative mt-4">
//                       <FaPhoneAlt className="absolute top-3 left-3 text-primary" />
//                       <input
//                         type="text"
//                         id="phone"
//                         placeholder="Phone"
//                         value={formData.phone}
//                         onChange={handleChange}
//                         className="w-full pl-10 p-3 border rounded focus:outline-none"
//                       />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Comments */}
//                 <div className="relative mt-4">
//                   <FaComment className="absolute top-3 left-3 text-primary" />
//                   <textarea
//                     id="comments"
//                     placeholder="Comments"
//                     value={formData.comments}
//                     onChange={handleChange}
//                     rows="4"
//                     className="w-full pl-10 p-3 border rounded focus:outline-none"
//                   />
//                 </div>

//                 {/* Submit Button */}
//                 <button
//                   type="submit"
//                   className="bg-primary text-white px-6 py-3 rounded hover:bg-secondary transition-colors"
//                 >
//                   Submit
//                 </button>
//               </form>
//             </div>

//             {/* Right Column: Contact Address */}
//             <div className="md:w-1/2">
//               <h2 className="text-2xl font-bold text-primary mb-4">Contact Address</h2>
//               <div className="space-y-4 text-lg leading-relaxed">
//                 <p>
//                   <strong>Shri Jivdaya Mandal Bhachau</strong>
//                 </p>
//                 {/* Address Lines */}
//                 <div className="flex items-start space-x-2">
//                   <FaMapMarkerAlt className="text-primary mt-1" />
//                   <div>
//                     <p>Kanksuri Ahisadham Marg, Bhavanipur</p>
//                     <p>Bhachau Kutch</p>
//                     <p>Pin: 370140</p>
//                   </div>
//                 </div>

//                 {/* Office No */}
//                 <div className="flex items-center space-x-2">
//                   <FaPhoneAlt className="text-primary" />
//                   <p>
//                     <span className="font-semibold">Office No:</span>{" "}
//                     <a
//                       href="tel:+919512342930"
//                       className="underline hover:text-primary"
//                     >
//                       +91 95123 42930
//                     </a>
//                   </p>
//                 </div>

//                 {/* WhatsApp No */}
//                 <div className="flex items-center space-x-2">
//                   <FaWhatsapp className="text-primary" />
//                   <p>
//                     <span className="font-semibold">WhatsApp No:</span>{" "}
//                     <a
//                       href="https://api.whatsapp.com/send?phone=918320974719&text=Hi, Im connected to your website shreekanaksuriahinsadham.com (powered by NEX CORP)"
//                       className="underline hover:text-primary"
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       +91 96382 42930
//                     </a>
//                   </p>
//                 </div>

//                 {/* Email */}
//                 <div className="flex items-center space-x-2">
//                   <FaEnvelope className="text-primary" />
//                   <p>
//                     <span className="font-semibold">Email:</span>{" "}
//                     <a
//                       href="mailto:kanaksuriahinsadhambhachau@gmail.com"
//                       className="underline hover:text-primary"
//                     >
//                       kanaksuriahinsadhambhachau@gmail.com
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* =====================
//           Google Map Section
//       ===================== */}
//       <section className="w-full">
//         <iframe
//           title="Google Map"
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14657.762265937201!2d70.32680817363787!3d23.299768807012228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950992b49c56b63%3A0xfa9858a061b9194!2sBhavanipur%2C+Bhachau%2C+Gujarat+370140!5e0!3m2!1sen!2sin!4v1519734342011"
//           className="w-full h-96 border-0"
//           allowFullScreen=""
//           loading="lazy"
//         ></iframe>
//       </section>
//     </main>
//   );
// };

// export default Contact;

// src/components/Contact.jsx
import React, { useState } from 'react';
import {
  FaUser,
  FaGlobe,
  FaEnvelope,
  FaPhoneAlt,
  FaComment,
  FaMapMarkerAlt,
  FaWhatsapp,
} from 'react-icons/fa';

function formatIndianPhoneDisplay(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  if (digits.length === 12 && digits.startsWith("91")) {
    const country = digits.slice(0, 2);
    const part1 = digits.slice(2, 7);
    const part2 = digits.slice(7);
    return `+${country} ${part1} ${part2}`;
  }
  return `+${digits}`;
}

function formatIndianPhoneLink(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    digits = "91" + digits;
  } else if (digits.length === 11 && digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (digits.startsWith("+91")) {
    digits = digits.slice(1);
  } else if (digits.startsWith("0")) {
    digits = "91" + digits.slice(1);
  } else if (!digits.startsWith("91")) {
    digits = "91" + digits;
  }
  return digits;
}

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    country: '',
    email: '',
    phone: '',
    comments: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Form submitted!');
    setFormData({
      name: '',
      country: '',
      email: '',
      phone: '',
      comments: '',
    });
  };

  return (
    <main className="bg-white">
      {/* Banner */}
      <section
        className="relative bg-center bg-cover h-72 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/contact_banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-4xl font-bold">Contact Us</h1>
        </div>
      </section>

      {/* Main Contact Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-8">
            {/* Contact Form */}
            <div className="md:w-1/2">
              <h2 className="text-2xl font-bold text-primary mb-4">Contact Us</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="flex-1">
                    <div className="relative">
                      <FaUser className="absolute top-3 left-3 text-primary" />
                      <input
                        type="text"
                        id="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full pl-10 p-3 border rounded focus:outline-none"
                        required
                      />
                    </div>
                    <div className="relative mt-4">
                      <FaGlobe className="absolute top-3 left-3 text-primary" />
                      <input
                        type="text"
                        id="country"
                        placeholder="Country"
                        value={formData.country}
                        onChange={handleChange}
                        className="w-full pl-10 p-3 border rounded focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="relative">
                      <FaEnvelope className="absolute top-3 left-3 text-primary" />
                      <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full pl-10 p-3 border rounded focus:outline-none"
                        required
                      />
                    </div>
                    <div className="relative mt-4">
                      <FaPhoneAlt className="absolute top-3 left-3 text-primary" />
                      <input
                        type="text"
                        id="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full pl-10 p-3 border rounded focus:outline-none"
                      />
                    </div>
                  </div>
                </div>

                <div className="relative mt-4">
                  <FaComment className="absolute top-3 left-3 text-primary" />
                  <textarea
                    id="comments"
                    placeholder="Comments"
                    value={formData.comments}
                    onChange={handleChange}
                    rows="4"
                    className="w-full pl-10 p-3 border rounded focus:outline-none"
                  />
                </div>

                <button
                  type="submit"
                  className="bg-primary text-white px-6 py-3 rounded hover:bg-secondary transition-colors"
                >
                  Submit
                </button>
              </form>
            </div>

            {/* Address Section */}
            <div className="md:w-1/2 space-y-8">
              {/* Location 1: Bhachau Office */}
              <div>
                <h2 className="text-2xl font-bold text-primary mb-4">Bhachau Office</h2>
                <div className="space-y-3 text-lg leading-relaxed">
                  <div className="flex items-start space-x-2">
                    <FaMapMarkerAlt className="text-primary mt-1" />
                    <div>
                      <p>Bhavanipur Bhachan,</p>
                      <p>Opp. Rotary Club, Bhachau</p>
                      <p>Pin: 370140</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaPhoneAlt className="text-primary" />
                    <a
                      href={`tel:+${formatIndianPhoneLink("9512342930")}`}
                      className="underline hover:text-primary"
                    >
                      {formatIndianPhoneDisplay("9512342930")}
                    </a>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaWhatsapp className="text-primary" />
                    <a
                      href={`https://wa.me/${formatIndianPhoneLink("9638242930")}?text=Hi`}
                      className="underline hover:text-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatIndianPhoneDisplay("9638242930")}
                    </a>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaEnvelope className="text-primary" />
                    <a
                      href="mailto:kanaksuriahinsadhambhachau@gmail.com"
                      className="underline hover:text-primary"
                    >
                      kanaksuriahinsadhambhachau@gmail.com
                    </a>
                  </div>
                </div>
              </div>

              {/* Location 2: Mumbai Office */}
              <div>
                <h2 className="text-2xl font-bold text-primary mb-4">Mumbai Office</h2>
                <div className="space-y-3 text-lg leading-relaxed">
                  <div className="flex items-start space-x-2">
                    <FaMapMarkerAlt className="text-primary mt-1" />
                    <div>
                      <p>Ritual Creation, 209 Cosmos Platinum,</p>
                      <p>Opp Ashish Industry, Gokhale Road,</p>
                      <p>Dadar-W, Mumbai</p>
                      <p>Pin: 400028</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaPhoneAlt className="text-primary" />
                    <a
                      href={`tel:+${formatIndianPhoneLink("09892566570")}`}
                      className="underline hover:text-primary"
                    >
                      {formatIndianPhoneDisplay("09892566570")}
                    </a>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaWhatsapp className="text-primary" />
                    <a
                      href={`https://wa.me/${formatIndianPhoneLink("09892566570")}?text=Hi`}
                      className="underline hover:text-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatIndianPhoneDisplay("09892566570")}
                    </a>
                  </div>
                </div>
              </div>

              {/* Location 3: Surat Office */}
              <div>
                <h2 className="text-2xl font-bold text-primary mb-4">Surat Office</h2>
                <div className="space-y-3 text-lg leading-relaxed">
                  <div className="flex items-start space-x-2">
                    <FaMapMarkerAlt className="text-primary mt-1" />
                    <div>
                      <p>Shop No. 322, Shiven Square,</p>
                      <p>Opposite Pyramid Platina,</p>
                      <p>Pal, Surat - 395009</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaPhoneAlt className="text-primary" />
                    <a
                      href={`tel:+${formatIndianPhoneLink("09512342930")}`}
                      className="underline hover:text-primary"
                    >
                      {formatIndianPhoneDisplay("09512342930")}
                    </a>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaWhatsapp className="text-primary" />
                    <a
                      href={`https://wa.me/${formatIndianPhoneLink("09512342930")}?text=Hi`}
                      className="underline hover:text-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatIndianPhoneDisplay("09512342930")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Google Map */}
      <section className="w-full">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14657.762265937201!2d70.32680817363787!3d23.299768807012228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950992b49c56b63%3A0xfa9858a061b9194!2sBhavanipur%2C+Bhachau%2C+Gujarat+370140!5e0!3m2!1sen!2sin!4v1519734342011"
          className="w-full h-96 border-0"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>
    </main>
  );
};

export default Contact;
