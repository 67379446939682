// src/api.js

export const BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080" // Local dev
    : process.env.REACT_APP_BACKEND_URL || "https://api.shreekanaksuriahinsadham.com";

// We'll call it API_BASE for convenience, matching your volunteer code usage.
export const API_BASE = BACKEND_URL;

/**
 * fetchJSON – a minimal helper that calls the backend.
 * Similar to the volunteer code's fetchJSON.
 */
export async function fetchJSON(endpoint, options = {}) {
  const url = `${API_BASE}${endpoint}`;
  const response = await fetch(url, options);

  let data;
  try {
    data = await response.json();
  } catch (parseError) {
    // If response body is not JSON, handle gracefully
    throw new Error(`Invalid JSON response: ${parseError.message}`);
  }

  if (!response.ok) {
    throw new Error(data.error || 'Request failed');
  }
  return data;
}
