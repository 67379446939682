// frontend/src/components/About.jsx
import React from 'react';
import { FaHandHoldingHeart, FaDonate, FaCamera } from 'react-icons/fa';
import { GiDove } from 'react-icons/gi';

const About = () => {
  return (
    <main>
      {/* PageBanner Section */}
      <section
        className="pagebanner-section relative bg-cover bg-center"
        style={{ backgroundImage: "url('/images/about_banner.jpg')" }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="container mx-auto px-4 py-20 relative">
          <div className="pagebanner-content text-white text-center">
            <h1 className="text-4xl font-bold">About: Shri JivDaya Mandal (Managed By Shree Kanaksuri Ahinsadham)</h1>
          </div>
        </div>
      </section>

      {/* About Content Section */}
      <section className="py-16">
        <div className="container mx-auto px-4 flex flex-col lg:flex-row gap-8">
          {/* Main About Content */}
          <div className="w-full lg:w-2/3">
            <div className="content-area">
              <div className="section-header mb-8">
                <h1 className="text-3xl font-bold text-primary">
                  Shri <span className="font-bold">JivDaya</span> Mandal
                </h1>
              </div>
              <div className="about-content text-dark space-y-6 text-lg">
                <p>
                  Jivdaya Trust warmly welcomes you all animal lovers. We have been serving in this field for over 52 years with full dedication and have helped more than 1600 animals.
                </p>
                <p>
                  Throughout the years, we have cared for injured, homeless, and unhealthy animals. We are always ready and eager to help those in need.
                </p>
                <p>
                  After serving countless animals, we recognized the need to enhance our infrastructure and facilities to provide better care. We are adopting the latest technology to continuously improve animal care.
                </p>
                <p>
                  As we expand our trust to help more underprivileged animals, we have developed{" "}
                  <strong>“Shri Kanaksuri Ahinshadham”</strong> to ensure the best results for our mission.
                </p>
                <p>
                  Through the "Shri Kansuri Ahinshadham" project, JivDaya Mandal provides top-notch facilities for every animal.
                </p>
                <h3 className="text-xl font-bold text-primary mt-8 mb-4">Our Motive</h3>
                <p>
                  Our trust’s motto is to help more animals so that every creature can enjoy a peaceful life.
                </p>
                <p>
                  With the blessings of the calm and revered Kohinoor of Kutch Gurudev, Shri Kansuri Ahinshadham, we are driven to build a better tomorrow for all animals.
                </p>
                <p>
                  We hope that our journey continues to be supported by kind-hearted donors like you.
                </p>
                <h3 className="text-xl font-bold text-primary mt-8">Project Facility</h3>
                <ul className="list-disc list-inside text-dark space-y-1">
                  <li>Main Gate</li>
                  <li>Office</li>
                  <li>Shader for grass</li>
                  <li>Shelter of animals</li>
                  <li>Advanced Hospital</li>
                  <li>Water Tank Facility</li>
                  <li>Care for Sick Animals</li>
                  <li>Pigeon House</li>
                  <li>Sevak Aavas</li>
                  <li>Operation Theater</li>
                  <li>JivDaya Gallery</li>
                  <li>X-ray Laboratory</li>
                  <li>Ambulance</li>
                </ul>
                {/* Download PDF */}
                <div className="download-pdf mt-8">
                  <a
                    href="/pdf/jivdaya.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-4 hover:underline"
                  >
                    <img
                      src="/images/jivdaya-thumb.jpg"
                      alt="JivDaya Thumbnail"
                      className="w-24 h-auto border border-primary rounded"
                    />
                    <span className="text-primary font-semibold">Download PDF</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Quick Enquiry Widget */}
          <div className="w-full lg:w-1/3">
            <div className="widget-area bg-gray-50 p-6 rounded shadow">
              <h3 className="text-2xl font-bold text-primary mb-4">Quick Enquiry</h3>
              <form className="space-y-4">
                <div className="flex items-center border-b border-gray-300 pb-2">
                  <i className="fa fa-user text-primary mr-3" aria-hidden="true" />
                  <input
                    type="text"
                    id="name"
                    placeholder="Name"
                    className="flex-grow focus:outline-none text-lg"
                  />
                </div>
                <div className="flex items-center border-b border-gray-300 pb-2">
                  <i className="fa fa-envelope-o text-primary mr-3" aria-hidden="true" />
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    className="flex-grow focus:outline-none text-lg"
                  />
                </div>
                <div className="flex items-center border-b border-gray-300 pb-2">
                  <i className="fa fa-phone-square text-primary mr-3" aria-hidden="true" />
                  <input
                    type="text"
                    id="phone"
                    placeholder="Phone"
                    className="flex-grow focus:outline-none text-lg"
                  />
                </div>
                <div className="flex items-center border-b border-gray-300 pb-2">
                  <i className="fa fa-comment text-primary mr-3" aria-hidden="true" />
                  <textarea
                    id="comments"
                    placeholder="Comments"
                    className="flex-grow focus:outline-none text-lg"
                    rows="3"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn-submit bg-primary text-white px-6 py-2 rounded mt-4 hover:bg-secondary transition-colors text-lg"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Our Journey Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-primary mb-8">Our Journey</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <TimelineCard
              year="1971"
              title="Trust Established"
              description="Jivdaya Trust was established with a vision to care for animals."
            />
            <TimelineCard
              year="1990"
              title="First Major Rescue"
              description="Our trust carried out its first major rescue, saving countless lives."
            />
            <TimelineCard
              year="2005"
              title="Expanded Facilities"
              description="We expanded our infrastructure to better serve animals."
            />
            <TimelineCard
              year="2020"
              title="Digital Transformation"
              description="Embracing technology for improved animal care and management."
            />
          </div>
        </div>
      </section>

      {/* CallOut Section */}
      <section className="callout-section bg-gray-200 py-16">
        <div className="container mx-auto px-4 text-center">
          <h3 className="text-3xl font-bold text-primary mb-4">
            Your Experience <span className="text-secondary">Matters!</span>
          </h3>
          <p className="text-dark mb-6 text-lg">
            Pacific Valley is privileged to give everyone an opportunity to share their experience!
          </p>
          <a
            href="#"
            className="bg-primary text-white px-8 py-3 rounded-full hover:bg-secondary transition-colors text-lg"
          >
            Share Now
          </a>
        </div>
      </section>
    </main>
  );
};

// Timeline Card component for Our Journey section
const TimelineCard = ({ year, title, description }) => (
  <div className="bg-white p-6 rounded shadow hover:shadow-lg transition">
    <div className="text-primary font-bold text-xl">{year}</div>
    <h4 className="font-bold text-lg text-dark mt-2">{title}</h4>
    <p className="text-dark mt-2 text-sm">{description}</p>
  </div>
);

export default About;
