import React from 'react';
import {
  FaUserCircle,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaWhatsapp,
  FaCity,
} from 'react-icons/fa';

const trustees = [
  {
    city: 'Bhachau',
    name: 'Satishbhai Ramaniklal Mehta',
    phone: '09879195995',
  },
  {
    city: 'Bhachau',
    name: 'Himmatlal Jethalal Mehta',
    phone: '09879989024',
  },
  {
    city: 'Ahmedabad',
    name: 'Jayeshbhai Pragjibhai Koradiya',
    phone: '09879113650',
  },
  {
    city: 'Dadar',
    name: 'Rohitbhai Dharamshibhai Kubadia',
    phone: '09820632115',
  },
];

const managingTrustee = {
  city: 'Bhuj',
  name: 'Dr. Bharatbhai A.D Mehta',
  phone: '09825225416',
};

function formatIndianPhoneDisplay(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) digits = "91" + digits;
  else if (digits.length === 11 && digits.startsWith("0")) digits = "91" + digits.slice(1);
  else if (!digits.startsWith("91")) digits = "91" + digits;
  const country = digits.slice(0, 2);
  const part1 = digits.slice(2, 7);
  const part2 = digits.slice(7);
  return `+${country} ${part1} ${part2}`;
}

function formatPhoneLink(rawNumber = "") {
  let digits = rawNumber.replace(/\D/g, "");
  if (digits.length === 10) return "91" + digits;
  else if (digits.length === 11 && digits.startsWith("0")) return "91" + digits.slice(1);
  else if (digits.startsWith("+91")) return digits.slice(1);
  else if (!digits.startsWith("91")) return "91" + digits;
  return digits;
}

const TrusteeMandal = () => {
  return (
    <main className="bg-white py-12 px-4 min-h-screen">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-primary mb-10">
          Trustee Mandal
        </h1>

        {/* Managing Trustee Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold text-dark mb-6 text-center">
            Managing Trustee
          </h2>
          <div className="bg-[#FCE1CB] border border-[#DFB499] p-6 rounded-lg shadow hover:shadow-2xl transition-all transform hover:scale-105 max-w-xl mx-auto">
            <div className="flex items-center mb-3 space-x-2">
              <FaCity className="text-primary" />
              <p className="text-lg font-medium text-dark">{managingTrustee.city}</p>
            </div>
            <div className="flex items-center mb-3 space-x-2">
              <FaUserCircle className="text-primary" />
              <p className="text-lg font-semibold text-dark">{managingTrustee.name}</p>
            </div>
            <div className="flex items-center mb-3 space-x-2">
              <FaPhoneAlt className="text-primary" />
              <a
                href={`tel:+${formatPhoneLink(managingTrustee.phone)}`}
                className="text-lg text-primary underline hover:text-secondary"
              >
                {formatIndianPhoneDisplay(managingTrustee.phone)}
              </a>
            </div>
            <div className="flex items-center space-x-2">
              <FaWhatsapp className="text-primary" />
              <a
                href={`https://wa.me/${formatPhoneLink(managingTrustee.phone)}?text=Hello`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-lg text-primary underline hover:text-secondary"
              >
                {formatIndianPhoneDisplay(managingTrustee.phone)}
              </a>
            </div>
          </div>
        </section>

        {/* Trustees Section */}
        <section>
          <h2 className="text-3xl font-semibold text-dark mb-6 text-center">
            Trustees
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {trustees.map((person, idx) => (
              <div
                key={idx}
                className="bg-[#FCE1CB] border border-[#DFB499] p-6 rounded-lg shadow hover:shadow-2xl transition-all transform hover:scale-105"
              >
                <div className="flex items-center mb-3 space-x-2">
                  <FaCity className="text-primary" />
                  <p className="text-lg font-medium text-dark">{person.city}</p>
                </div>
                <div className="flex items-center mb-3 space-x-2">
                  <FaUserCircle className="text-primary" />
                  <p className="text-lg font-semibold text-dark">{person.name}</p>
                </div>
                <div className="flex items-center mb-3 space-x-2">
                  <FaPhoneAlt className="text-primary" />
                  <a
                    href={`tel:+${formatPhoneLink(person.phone)}`}
                    className="text-lg text-primary underline hover:text-secondary"
                  >
                    {formatIndianPhoneDisplay(person.phone)}
                  </a>
                </div>
                <div className="flex items-center space-x-2">
                  <FaWhatsapp className="text-primary" />
                  <a
                    href={`https://wa.me/${formatPhoneLink(person.phone)}?text=Hello`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg text-primary underline hover:text-secondary"
                  >
                    {formatIndianPhoneDisplay(person.phone)}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </main>
  );
};

export default TrusteeMandal;
