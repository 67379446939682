// src/components/ThreeDPhoto.jsx
import React from 'react';

const ThreeDPhoto = () => {
  // Suppose you have 12 images about_lg-1.jpg to about_lg-12.jpg in /images/banner
  const images = [
    'about_lg-1.jpg',
    'about_lg-2.jpg',
    'about_lg-3.jpg',
    'about_lg-4.jpg',
    'about_lg-5.jpg',
    'about_lg-6.jpg',
    'about_lg-7.jpg',
    'about_lg-8.jpg',
    'about_lg-9.jpg',
    'about_lg-10.jpg',
    'about_lg-11.jpg',
    'about_lg-12.jpg',
  ];

  return (
    <main className="bg-white">
      {/* Banner */}
      <section
        className="relative bg-center bg-cover h-72 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/banner/gallery_banner.jpg')" }}
      >
        <div className="bg-black bg-opacity-40 absolute inset-0" />
        <div className="relative z-10 text-center text-white">
          <h1 className="text-4xl font-bold">Our Photos</h1>
        </div>
      </section>

      {/* Title */}
      <section className="py-8">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-3xl font-bold mb-2 text-primary">3D Photo</h1>
        </div>
      </section>

      {/* Grid */}
      <section className="container mx-auto px-4 pb-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {images.map((filename, idx) => (
            <div className="relative group" key={idx}>
              <img
                src={`/images/banner/${filename}`}
                alt={`3D gallery ${idx}`}
                className="w-full object-cover"
              />
              <a
                href={`/images/banner/${filename}`}
                target="_blank"
                rel="noreferrer"
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 hover:bg-opacity-50 transition"
              >
                <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100 transition" />
              </a>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default ThreeDPhoto;
